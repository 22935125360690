import {
    CancelController,
    CreateGroupType,
    CreateObjectType,
    DeviceType,
    EditGroupType,
    EditObjectType,
    GetGroupType,
    GroupType,
    HostType,
    LoginFormType,
    RegistrationFormType,
    RequestParams
} from '../Types/Types'
import {AxiosResponse} from 'axios'

// http://localhost/api/index.php?r=api/    https://lk.distsystems.ru/api/index.php?r=api/
export const baseURL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost/api/index.php?r=api/' : '/api/index.php?r=api/'

const axios = require('axios')
const instance = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json'
    },
})

export const authAPI = {
    login(form: LoginFormType): ResponseType<string> {
        return instance.post('user_login', {...form}, {signal: form?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    registration(form: RegistrationFormType): ResponseType<boolean> {
        return instance.post('user_register', {...form}, {signal: form?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    authCheck(token?: string): ResponseType<AuthCheckResType> {
        return instance.post('user_check', token && {auth: token}).then((res: AxiosResponse) => res.data)
    },
    confirmRegistration(params: CompleteRegistrationType): ResponseType<CompleteRegistrationResType> {
        return instance.post('user_complete_registration', {token: params?.token}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    sendTelegramCode(params: CompleteRegistrationType): ResponseType<CompleteRegistrationResType> {
        return instance.post('user_send_telegram_code', {auth: params?.token}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    checkTelegramCode(params: CheckTelegramCodeType): ResponseType<CompleteRegistrationResType> {
        return instance.post('user_confirm_telegram_code', {auth: params?.token, code: params?.code}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getProfile(params: GetProfileType): ResponseType<ProfileResType> {
        return instance.post('profile_get', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editProfile(params: EditProfileType): ResponseType<ProfileResType> {
        return instance.post('profile_edit', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

export const problemAPI = {
    getProblems(params: RequestParams): ResponseType<Array<HostType>> {
        return instance.post('problems_get', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getProblem(params: GetObjectType): ResponseType<Array<HostType>> {
        return instance.post('problem_get', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

export const hostAPI = {
    getHosts(params: RequestParams): ResponseType<Array<HostType>> {
        return instance.post('hosts_get', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getHost(params: GetObjectType): ResponseType<HostType> {
        return instance.post('host_get', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createHost(params: CreateObjectType & { auth: string }): ResponseType<HostType> {
        return instance.post('host_create', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editHost(params: EditObjectType & { auth: string }): ResponseType<HostType> {
        return instance.post('host_edit', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    deleteHost(params: CreateObjectType & { auth: string }): ResponseType<HostType> {
        return instance.post('host_delete', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getCities(params: CancelController): ResponseType<Array<HostType>> {
        return instance.post('cities_get', {}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

export const groupAPI = {
    getGroups(params: RequestParams): ResponseType<Array<GroupType>> {
        return instance.post('hostgroups_get', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getGroup(params: GetGroupType): ResponseType<Array<HostType>> {
        return instance.post('hostgroup_get', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createGroup(params: CreateGroupType): ResponseType<string> {
        return instance.post('hostgroup_create', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editGroup(params: EditGroupType): ResponseType<string> {
        return instance.post('hostgroup_edit', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    deleteGroup(params: CreateObjectType & { auth: string }): ResponseType<HostType> {
        return instance.post('hostgroup_delete', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

export const deviceAPI = {
    getDevice(params: GetObjectType): ResponseType<DeviceType> {
        return instance.post('item_get', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createDevice(params: CreateObjectType & { auth: string }): ResponseType<DeviceType> {
        return instance.post('item_create', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editDevice(params: EditObjectType & { auth: string }): ResponseType<DeviceType> {
        return instance.post('item_edit', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    deleteDevice(params: CreateObjectType & { auth: string }): ResponseType<DeviceType> {
        return instance.post('item_delete', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getEvents(params: GetObjectType): ResponseType<DeviceType> {
        return instance.post('events_get', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

export const statisticsAPI = {
    getStatistics(): ResponseType<any> {
        return instance.get('statistics_get').then((res: AxiosResponse) => res.data)
    },
}

export const AjaxAPI = {
    getAjaxAuthAll(params: GetObjectType): ResponseType<DeviceType> {
        return instance.post('ajax_auth_all_get', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createAjaxAuth(params: CreateAjaxAuthType): ResponseType<DeviceType> {
        return instance.post('ajax_auth_create', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editAjaxAuth(params: CreateAjaxAuthType & {id: number}): ResponseType<DeviceType> {
        return instance.post('ajax_auth_edit', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    deleteAjaxAuth(params: CancelController & {id: number}): ResponseType<DeviceType> {
        return instance.post('ajax_auth_delete', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getItems(params: GetItemsAjaxType): ResponseType<DeviceType> {
        return instance.post('ajax_items_get', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createHub(params: CreateAjaxHub): ResponseType<DeviceType> {
        return instance.post('ajax_hub_create', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editHub(params: EditAjaxHub): ResponseType<DeviceType> {
        return instance.post('ajax_hub_edit', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createItem(params: CreateAjaxDevice): ResponseType<DeviceType> {
        return instance.post('ajax_item_create', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editItem(params: EditAjaxDevice): ResponseType<DeviceType> {
        return instance.post('ajax_item_edit', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

interface ResponseType<T> {
    result: T
    error: ErrorType
}

interface AuthCheckResType {
    login: string
    type: number
    id: string
}

interface ErrorType {
    message: string
    code: number
}

export interface GetObjectType extends CancelController {
    id: string
    auth: string
}

interface CompleteRegistrationResType {

}

interface ProfileResType {

}

interface CompleteRegistrationType extends CancelController {
    token: string
}

interface CheckTelegramCodeType extends CancelController {
    token: string
    code: string
}

interface GetProfileType extends CancelController {
    token: string
}

export interface EditProfileType extends CancelController {
    name: string
    telephone: string
    telegram_id: string
    timezone: string
    email_notification: boolean
    telegram_notification: boolean
    ajax_systems: boolean
}

export interface CreateAjaxAuthType extends CancelController {
    profileId: string
    login: string
    password: string
}

export interface GetItemsAjaxType extends CancelController {
    auth: string
    idAjaxHub: string
    idAjaxAuth: number
}

export type CreateAjaxHub = {
    hostId: string
    name: string
    idAjaxDevice: string
    priority: number
    status: string
    idAjaxHub: string
    idAjaxAuth: string
    auth: string
} & CancelController

export type CreateAjaxDevice = {
    hostId: string
    name: string
    idAjaxDevice: string
    idAjaxHub: string
    priority: number
    status: string
    masterItemId: string
    auth: string
    ajaxTriggerType: 'online' | 'battery' | 'tamper'
} & CancelController

export type EditAjaxHub = {
    id: string
    hostId: string
    name: string
    status: string
    priority: number
} & CancelController

export type EditAjaxDevice = {
    id: string
    hostId: string
    name: string
    status: string
    priority: number
} & CancelController