import React, {memo, useEffect, useMemo, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {StateType} from '../../store/store'
import {getGroups} from '../../store/groupsReducer'
import {Helmet} from 'react-helmet-async'
import {GroupType} from '../../Types/Types'
import {Doughnut} from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {ArcElement, Chart as ChartJS, Tooltip} from 'chart.js'

ChartJS.register(ArcElement, Tooltip, ChartDataLabels)

export const Analytics = () => {
    const dispatch = useDispatch()
    const groups = useSelector((state: StateType) => state.groupsReducer.groups)
    const controller = useMemo(() => new AbortController(), [])
    const timer = useRef<any>()

    useEffect(() => {
        dispatch(getGroups({controller}))
        timer.current = setInterval(() => dispatch(getGroups({controller, noLoading: true})), 30000)

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, controller])

    const analytics = useMemo(() => groups && groups.map(i => <GroupItem key={i.id} item={i}/>), [groups])

    return (
        <div className="container" style={{flex: 1}}>
            <Helmet>
                <title>Аналитика</title>
            </Helmet>
            <h2 className="mb-2">Аналитика</h2>
            {analytics}
        </div>
    )
}

type GroupItemType = {
    item: GroupType
}
const GroupItem: React.FC<GroupItemType> = memo(({item}) => {
    const {name, hosts} = item

    if (!hosts || hosts.length < 1) return null

    let hostsNoProblem = 0
    let hostsHighProblem = 0
    let hostsMediumProblem = 0
    let hostsLowProblem = 0

    let devicesNoProblem = 0
    let devicesHighProblem = 0
    let devicesMediumProblem = 0
    let devicesLowProblem = 0

    hosts?.forEach(host => {
        let items = host?.items
        let maxProblem = 0

        items?.forEach(item => {
            if (item.value !== 0) {
                if (item.priority === 4) {
                    devicesHighProblem += 1
                    if (4 > maxProblem) maxProblem = 4
                }
                if (item.priority === 3) {
                    devicesMediumProblem += 1
                    if (3 > maxProblem) maxProblem = 3
                }
                if (item.priority === 1) {
                    devicesLowProblem += 1
                    if (1 > maxProblem) maxProblem = 1
                }
            } else {
                devicesNoProblem += 1
            }
        })

        if (maxProblem === 0) hostsNoProblem += 1
        if (maxProblem === 1) hostsLowProblem += 1
        if (maxProblem === 3) hostsMediumProblem += 1
        if (maxProblem === 4) hostsHighProblem += 1
    })

    const getChart = (noProblem: number, lowProblem: number, mediumProblem: number, highProblem: number) => {

        const data = {
            labels: [],
            datasets: [
                {
                    data: [noProblem, lowProblem, mediumProblem, highProblem],
                    backgroundColor: [
                        '#4966f8',
                        '#b2ae8f',
                        '#ff8e21',
                        '#ff3333'
                    ],
                    borderColor: [
                        '#4966f8',
                        '#b2ae8f',
                        '#ff8e21',
                        '#ff3333'
                    ],
                    borderWidth: 1,
                },
            ]
        }

        return (
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', margin: 20}}>
                <Doughnut data={data}
                          style={{margin: 10, maxHeight: 200, maxWidth: 200}}
                          options={{
                              plugins: {
                                  datalabels: {
                                      color: 'black',
                                      font: {
                                          size: 12
                                      },
                                      display: 'auto',
                                      formatter: (value) => {
                                          return value === 0 ? '' : value
                                      }
                                  }
                              }
                          }}
                />
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 10, minWidth: 160}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 5}}>
                        <div style={{width: 16, height: 16, borderRadius: 8, marginRight: 5, backgroundColor: '#4966f8'}}/>
                        <div style={{fontSize: 11.5}}>{`Без проблем (${noProblem})`}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 5}}>
                        <div style={{width: 16, height: 16, borderRadius: 8, marginRight: 5, backgroundColor: '#b2ae8f'}}/>
                        <div style={{fontSize: 11.5}}>{`Низкий приоритет (${lowProblem})`}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 5}}>
                        <div style={{width: 16, height: 16, borderRadius: 8, marginRight: 5, backgroundColor: '#ff8e21'}}/>
                        <div style={{fontSize: 11.5}}>{`Средний приоритет (${mediumProblem})`}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 5}}>
                        <div style={{width: 16, height: 16, borderRadius: 8, marginRight: 5, backgroundColor: '#ff3333'}}/>
                        <div style={{fontSize: 11.5}}>{`Высокий приоритет (${highProblem})`}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 20}}>
            <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 10}}>{name}</div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{fontSize: 14, fontWeight: 'bold'}}>Объекты ({hostsNoProblem + hostsLowProblem + hostsMediumProblem + hostsHighProblem})</div>
                    {getChart(hostsNoProblem, hostsLowProblem, hostsMediumProblem, hostsHighProblem)}
                </div>
                {
                    devicesNoProblem + devicesLowProblem + devicesMediumProblem + devicesHighProblem > 0
                        ? <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{fontSize: 14, fontWeight: 'bold'}}>Оборудование ({devicesNoProblem + devicesLowProblem + devicesMediumProblem + devicesHighProblem})</div>
                            {getChart(devicesNoProblem, devicesLowProblem, devicesMediumProblem, devicesHighProblem)}
                        </div>
                        : null
                }
            </div>
        </div>
    )
})
