import isObject from 'isobject'
import moment from 'moment'

export const filterToString = (filter: Object | undefined): string => {
    if (filter) {
        const entries = Object.entries(filter)
        let res: Array<string> = []

        entries.forEach(i => {
            const key = i[0]
            const value = i[1]

            if (key === 'withoutLoadingIndicator') {
                return
            } else if (value === undefined || value === null || value === '') {
                return
            } else if (key === 'dateFrom' || key === 'dateTo') {
                res.push(`${key}=${moment(value, 'ddd MMM DD YYYY HH:mm:ss ZZ').format('YYYY.MM.DD HH:mm:ss').replace(' ', '%20')}`)
            } else if (isObject(value)) res.push(`${key}=${value?.value}`)
            else if (Array.isArray(value)) {
                value.forEach(({value}) => res.push(`${key}=${value}`))
            } else res.push(`${key}=${value}`)
        })

        if (res.length > 0) return '?' + res.join('&')
        else return ''
    }
    return ''
}
