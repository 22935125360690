import React, {useEffect, useMemo, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {StateType} from '../../store/store'
import {priorityColorDefinitionBackground} from '../../helpers/priorityColorDefinition'
import {usePagination} from '../../hooks/usePagination'
import Select from 'react-select'
import {limitOptionsSelect} from '../../helpers/tableHelper'
import {getProblems, setProblemsFilter} from '../../store/problemsReducer'
import {NavLink, useHistory, useLocation} from 'react-router-dom'
import {filterToString} from '../../helpers/filterToString'
import {filterFromString} from '../../helpers/filterFromString'
import deepEqual from 'deep-equal'
import {Helmet} from 'react-helmet-async'
import {Loading} from '../../components/Loading/Loading'

export const Problems = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const problems = useSelector((state: StateType) => state.problemsReducer.problems)
    const filter = useSelector((state: StateType) => state.problemsReducer.filter)
    const {CustomPagination} = usePagination(filter, problems?.length, (value) => {
        dispatch(setProblemsFilter(value))
        history.push({search: filterToString(value)})
        dispatch(getProblems({controller}))
    })
    const controller = useMemo(() => new AbortController(), [])
    const timer = useRef<any>()

    useEffect(() => {
        if (problems) {
            const filterString = filterFromString(location.search)
            if (filterString && !deepEqual(filterString, filter)) {
                dispatch(setProblemsFilter({...filterString}))
            } else if (location.search !== filterToString(filter)) {
                history.replace({search: filterToString(filter)})
            }
        }
    }, [dispatch, filter, history, location, problems])

    useEffect(() => {
        dispatch(getProblems({controller}))
        timer.current = setInterval(() => dispatch(getProblems({controller, noLoading: true})), 30000)

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, controller])

    const problemsMap = useMemo(() => problems && [...problems].slice(filter.offset, filter.limit.value + filter.offset).map(i => {
        let priority = 0

        i.items?.forEach(item => {
            if (item.value === 1 && item.priority > priority) {
                priority = item.priority
            }
        })

        return (
            <tr key={i.id} style={{backgroundColor: priorityColorDefinitionBackground(priority)}}>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/problems/${i.id}`}>{i.id}</NavLink></td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/problems/${i.id}`}>{i.name}</NavLink></td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/groups/${i.groupId}`}>{i.groupName}</NavLink></td>
                <td className="align-middle">{i.address}</td>
                <td className="align-middle">{i.items?.map((item, index) => {
                    const lastItem = i.items.length === index + 1
                    return <span key={item.id}><NavLink className="custom-link-table-item" to={`/devices/${item.id}`}>{item.name}</NavLink>{!lastItem ? ', ' : ''}</span>
                })}
                </td>
            </tr>
        )
    }), [problems, filter])

    return (
        <div className="table-responsive container" style={{flex: 1}}>
            <Helmet>
                <title>Проблемы</title>
            </Helmet>
            <h2 className="mb-2">Проблемы</h2>
            <div className="selectBlock">
                <Select className="searchSelect"
                        value={filter.limit}
                        onChange={(item) => history.replace({search: filterToString({...filter, limit: item, offset: 0})})}
                        options={limitOptionsSelect}
                        isSearchable={false}
                />
                <CustomPagination/>
            </div>
            <table className="table table-sm">
                <thead>
                <tr>
                    <th className="align-middle" scope="col">ID</th>
                    <th className="align-middle" scope="col">Объект</th>
                    <th className="align-middle" scope="col">Группа</th>
                    <th className="align-middle" scope="col">Адрес</th>
                    <th className="align-middle" scope="col">Оборудование с проблемами</th>
                </tr>
                </thead>
                <tbody>
                {problems ? problemsMap : <tr>
                    <td colSpan={5}><Loading/></td>
                </tr>}
                </tbody>
            </table>
            <CustomPagination/>
        </div>
    )
}
