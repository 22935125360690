import React from 'react'

export const Agreement = () => {
    return (
        <div className="container WordSection1">
            <p className="MsoNormal text-center"><b><span>Публичный договор-оферта на предоставление услуг</span></b></p>

            <p className="MsoNormal"><b><span>&nbsp;</span></b></p>

            <p className="MsoNormal"><span>ООО «Ромашка», именуемое в дальнейшем «Исполнитель» в лице Директора <span className="SpellE">Ромашкина</span> Ромашки,
действующего на основании Устава, предлагает Клиенту заключить Договор на
предоставление услуг сервиса по мониторингу систем видеонаблюдения.</span></p>

            <p className="MsoNormal"><span>Данное <span>&nbsp;</span>предложение является <span>&nbsp;</span>публичной офертой (далее по тексту –
«Оферта»), составленной в соответствии со ст. 437 Гражданского кодекса
Российской Федерации (далее по тексту – ГК РФ). </span></p>

            <p className="MsoNormal"><span>Оферта содержит существенные условия
оказания услуг, определяет права, обязанности и ответственность Исполнителя и
Клиента. </span></p>

            <p className="MsoNormal"><span>В рамках Оферты Исполнитель и Клиент <span>&nbsp;</span>совместно именуются «Стороны».</span></p>

            <p className="MsoNormal"><span>&nbsp;</span></p>

            <p className="MsoListParagraph text-center"><b><span><span>1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><b><span>Термины
и определения</span></b></p>

            <p className="MsoNormal"><span>В целях настоящей Оферты нижеприведенные
термины используются в следующих значениях: </span></p>

            <p className="MsoNormal"><span>1.1. Акцепт – полное и безоговорочное
принятие Оферты, путём осуществления действий, указанных в разделе Порядок
заключения договора. </span></p>

            <p className="MsoNormal"><span>1.2. Договор – настоящее соглашение
между Исполнителем и Клиентом, составленное в виде публичной Оферты,
безоговорочно принятое Клиентом в полном объёме. </span></p>

            <p className="MsoNormal"><span>1.3. Клиент – физическое лицо/ юридическое
лицо/ инсталлятор, выразившие Акцепт, и имеющие намерение использовать услуги
Сервиса. Инсталлятор использует Сервис с целью извлечения прибыли на его основе.
</span></p>

            <p className="MsoNormal"><span>1.4. Услуга – доступ к Сервису. </span></p>

            <p className="MsoNormal"><span>1.5. Сервис – аппаратно-программный
комплекс мониторинга систем видеонаблюдения (иных систем), размещённый в сети
Интернет по адресу: <br/>
</span><a href="http://______________________"><span>http://</span></a><span><span>&nbsp; </span>, позволяющий периодически проверять
состояние работоспособности оборудования систем видеонаблюдения: регистратора,
жесткого диска и камер видеонаблюдения, а так же иного оборудования в
соответствии с функциональными возможностями Сервиса и выбором<span>&nbsp; </span>Клиента.</span><span className="markedcontent"> </span></p>

            <p className="MsoNormal"><span>1.6. Элемент данных – значение
результата проверки определенного параметра оборудования.</span></p>

            <p className="MsoNormal"><span>1.7. Личный кабинет Клиента – персональный
раздел Клиента в информационной базе Сервиса, при помощи которого можно просматривать
статистику работы оборудования, информацию о текущем состоянии лицевого счёта.
Доступ к Личному кабинету осуществляется Клиентом посредством ввода учётной
информации Клиента. </span></p>

            <p className="MsoNormal"><span>1.8. Регистрационные/учётные данные –
уникальное имя Клиента (логин) и пароль для входа в личный кабинет. </span></p>

            <p className="MsoNormal"><span>1.9. Тариф – совокупность условий о
ценах по каждому объему и <br/>
виду предоставляемой Услуги, в зависимости от типа Клиента (физическое лицо/
юридическое лицо/ инсталлятор), содержащие также информацию о системных
требованиях для пользования услугой и иных требованиях по каждому из
действующих тарифов. </span></p>

            <p className="MsoNormal"><span>&nbsp;</span></p>

            <p className="MsoListParagraph text-center"><b><span><span>2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><b><span>Предмет
Договора</span></b></p>

            <p className="MsoNormal"><span>2.1.
Исполнитель предоставляет Клиенту доступ к Сервису для использования Клиентом
программного и аппаратного обеспечения Исполнителя, а Клиент обязуется
оплачивать оказанные услуги в порядке и в сроки, установленные настоящим
Договором. </span></p>

            <p className="MsoNormal"><span>2.2.
При заключении настоящего Договора Клиент выражает безусловное согласие с
техническими, системными и иными требованиями относительно условий
предоставления Сервиса, которые размещен на официальном сайте Исполнителя в
разделе: </span><a href="http://www.tcenter.ru/tariffs/home"><span>http://
</span></a>.<span></span></p>

            <p className="MsoNormal"><span>2.3.
Исполнитель обладает всеми имущественными и неимущественными правами в <br/>
отношении Сервиса, включая любые права интеллектуальной собственности (вне
зависимости от того, зарегистрированы такие права или нет).</span></p>

            <p className="MsoNormal"><span>&nbsp;</span></p>

            <p className="MsoListParagraph text-center"><b><span><span>3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><b><span>Порядок
заключения договора</span></b></p>

            <p className="MsoNormal"><span>3.1.
Настоящий Договор заключается путем акцепта Клиентом настоящей публичной
оферты, содержащей все существенные условия договора<span>.</span>
При совершении Клиентом Акцепта, Клиент и Исполнитель считаются заключившими
Договор согласно ст. 438 ГК РФ.</span></p>

            <p className="MsoNormal"><span>3.2.
<span className="GramE">Полным и безоговорочным акцептом настоящей публичной оферты
является <br/>
зачисление денежных средств Клиентом на личный счет в качестве предоплаты за
оказанные Исполнителем услуги в соответствии с выбранным Клиентом Тарифным <br/>
планом.</span></span></p>

            <p className="MsoNormal text-center"><span><br/>
<b>4. Условия и порядок предоставления
Сервиса</b></span></p>

            <p className="MsoNormal"><span>4.1.
Необходимым условием для предоставления Сервиса является наличие доступа к сети
Интернет в месте установки оборудования, на период использования Клиентом
Сервиса. Наличие подключения к сети Интернет с достаточной пропускной
способностью <br/>
обеспечивает Клиент.</span></p>

            <p className="MsoNormal"><span>4.2.
Необходимым условием для предоставления Сервиса является надлежащая настройка
оборудования, которую обеспечивает Клиент, согласно инструкциям Исполнителя,
размещенным по адресу: </span><a href="http://www.tcenter.ru/tariffs/home"><span>http://
</span></a>.<span></span></p>

            <p className="MsoNormal"><span>4.3.
В процессе оказания Услуги Исполнитель направляет уведомления, по указанным в
Личном кабинете Клиента каналам связи: адреса электронной почты, номера
мобильных телефонов. Доступность каналов связи обеспечивает Клиент.</span></p>

            <p className="MsoNormal"><span>4.4.
Перед началом использования Сервиса Клиент должен получить регистрационные/учетные
данные (логин и пароль) для входа в Личный кабинет Сервиса по адресу: </span><a href="http://www.tcenter.ru/tariffs/home"><span>http:// </span></a>.<span></span></p>

            <p className="MsoNormal"><span>4.5.
Клиент не должен сообщать регистрационные данные третьим лицам. Если пароль <br/>
учетной записи Клиента будет известен другим лицам, то и информация, полученная
при <br/>
использовании Услуги, может быть доступна посторонним. Клиент соглашается с
тем, что <br/>
несет ответственность за сохранение пароля в тайне и безопасности.</span></p>

            <p className="MsoNormal text-center"><span><br/>
</span><b><span>5. Порядок оплаты</span></b><span> </span></p>

            <p className="MsoNormal"><span>5.1.
Тарифы на Услуги дифференцируются исходя из типа Клиента (физическое лицо/
юридическое лицо/ инсталлятор) и публикуются на сайте по следующему адресу: </span><a href="http://www.tcenter.ru/tariffs/home"><span>http:// </span></a>.<span></span></p>

            <p className="MsoNormal"><span>5.2.
Исполнитель оказывает услуги по Тарифам с абонентской платой. Размер
абонентской платы зависит от выбранного Клиентом Тарифного плана. Абонентская
плата вносится авансом до начала расчетного периода. Расчетный период составляет
календарный месяц.</span></p>

            <p className="MsoNormal"><span>5.3.
После получения Регистрационных/учетных данных Клиенту предоставляется пробный
период бесплатного доступа к Сервису для одной единицы оборудования сроком на
двадцать календарных дней, по истечении которого Клиент либо отказывается от использования
Сервиса, либо вносит на личный счет денежные средства в размере не <span className="GramE">менее абонентской</span> платы за месяц.</span></p>

            <p className="MsoNormal"><span>5.4.
Исполнитель вправе изменять в одностороннем порядке Тарифы, принципы <br/>
тарификации, виды тарификации, единицу тарификации и порядок оплаты неполной
единицы <span>&nbsp;</span>тарификации. Обязательным
условием является уведомление Клиента о таких изменениях путем их публикации на
сайте по адресу: </span><a href="http://www.tcenter.ru/tariffs/home"><span>http://
</span></a><span>&nbsp;</span><span>и/или путем
информирования по указанным Клиентом в личном кабинете каналам связи.</span></p>

            <p className="MsoNormal"><span>5.5.
Испонитель вправе устанавливать скидки (премии) к действующим Тарифам исходя из
количества оплаченных или потребленных Клиентом Услуг, а также иные специальные
предложения. Информация о специальных предложениях публикуется на сайте по
адресу: </span><a href="http://www.tcenter.ru/tariffs/home"><span>http://
</span></a><span><span>&nbsp;</span>, иными способами доводится до сведения
клиента.</span></p>

            <p className="MsoNormal"><span>5.6.
<span className="GramE">При отсутствии на балансе личного счета Клиента суммы, равной
ежемесячной абонентской плате, на момент наступления нового расчетного периода
или при отрицательном балансе, Исполнитель блокирует Клиенту доступ к Услуге до
момента пополнения личного счета Клиента денежными средствами<span>&nbsp; </span>в достаточном для оплаты услуги количестве.</span></span></p>

            <p className="MsoNormal"><span>5.7.
Если с момента прекращения пользования услугой или отрицательного баланса на личном
счете Клиента прошло более 180 (ста <span className="SpellE">восемьдесяти</span>)
дней, Исполнитель <span>&nbsp;</span>вправе удалить учетную
запись и информацию Клиента с сервера. </span></p>

            <p className="MsoNormal"><span>5.8.
Исполнитель ведет учет потребления и оплаты Услуг с помощью своих учетных <br/>
средств. Текущий баланс личного счета Клиент может посмотреть в своем Личном кабинете
или запросить у Исполнителя.</span></p>

            <p className="MsoNormal"><span>&nbsp;</span></p>

            <p className="MsoNormal text-center"><b><span>6.
Права, обязанности и ответственность сторон</span></b></p>

            <p className="MsoNormal"><span>6.1.
Исполнитель обязуется:</span></p>

            <p className="MsoNormal"><span>6.1.1.
Предоставлять Клиенту доступ к Сервису. </span></p>

            <p className="MsoNormal"><span>6.1.2.
Уведомлять Клиента о состоянии работоспособности оборудования по указанным в
Личном кабинете Клиента каналам связи: адреса электронной почты, номера
мобильных телефонов. При этом услуга считается фактически оказанной и
тарифицируется в полном объеме, даже если ее результат, в частности уведомление
о состоянии работоспособности оборудования,<span>&nbsp;
</span>не был доставлен Клиенту по причине недоступности каналов связи или
оборудования Клиента.</span></p>

            <p className="MsoNormal"><span>6.1.3.
Хранить информацию о состоянии работоспособности оборудования не более 90
(девяносто) дней с момента фиксации.</span></p>

            <p className="MsoNormal"><span>6.1.4.
Реагировать на обращения Клиента в службу технической поддержки.</span></p>

            <p className="MsoNormal"><span>6.1.5.
Уведомлять Клиента о внесении изменений в условия настоящей оферты, о внесении
изменений в работу Сервиса, об изменении в Тарифном плане, а также о появлении
новых услуг и продуктов Сервиса путем опубликования информации на сайте по
адресу: </span><a href="http://www.tcenter.ru/tariffs/home"><span>http://
</span></a><span>&nbsp;</span><span>и/или путем информирования
по указанным Клиентом в личном кабинете каналам связи. </span></p>

            <p className="MsoNormal"><span>6.2.
Клиент обязуется:</span></p>

            <p className="MsoNormal"><span>6.2.1.
Добросовестно пользоваться предоставленным Сервисом в соответствии с <br/>
условиями Договора, а также соблюдать нормы действующего законодательства РФ. <br/>
6.2.2. Своевременно и в полном объёме оплачивать услуги Исполнителя.</span></p>

            <p className="MsoNormal"><span>6.2.3.
Не осуществлять (и не пытаться осуществить) доступ к Сервису Исполнителя иным <br/>
способом, кроме как через предоставленный интерфейс.</span></p>

            <p className="MsoNormal"><span>6.2.4.
Незамедлительно уведомить Исполнителя, если станет известно о любом <br/>
несанкционированном использовании Личного кабинета. </span></p>

            <p className="MsoNormal"><span>6.3.
Исполнитель вправе: </span></p>

            <p className="MsoNormal"><span>6.3.1.
В одностороннем порядке пересматривать цены на услуги. </span></p>

            <p className="MsoNormal"><span>6.3.2.
Требовать оплаты за предоставленный Клиенту Сервис.</span></p>

            <p className="MsoNormal"><span>6.3.3.
Производить исправления программных ошибок, улучшать функции новых <br/>
модулей программного обеспечения.</span></p>

            <p className="MsoNormal"><span>6.3.4.
Автоматически загружать и устанавливать обновления на <span>&nbsp;</span>используемом Клиентом программном обеспечении
в рамках Услуг. <span className="GramE">Такие обновления созданы для того, чтобы улучшать
или развивать Сервис и могут быть предоставлены в форме <br/>
отдельных исправлений программных ошибок, улучшенных функций, новых модулей <br/>
программного обеспечения или полностью новых версий.</span> Клиент соглашается
получать и <br/>
разрешает Исполнителю предоставлять такие обновления в рамках использования
Услуги. </span></p>

            <p className="MsoNormal"><span>6.3.5.
Приостанавливать работу Сервиса для проведения необходимых плановых <br/>
профилактических и ремонтных работ на технических ресурсах, а также <br/>
внеплановых работ в аварийных ситуациях.</span></p>

            <p className="MsoNormal"><span>6.3.6.
Прерывать работу Сервиса, если это, в частности, обусловлено невозможностью <br/>
использования информационно-транспортных каналов, не являющихся собственными <br/>
ресурсами Исполнителя, либо действием и/или бездействием третьих лиц, если это <br/>
непосредственно влияет на работу Сервиса, в том числе при аварийной ситуации. </span></p>

            <p className="MsoNormal"><span>6.3.7.
Использовать в рекламных целях отзывы и комментарии, оставленные Клиентом <br/>
в информационной базе Сервиса. </span></p>

            <p className="MsoNormal"><span>6.3.9.
Приостановить доступ Клиента к Сервису без предварительного уведомления в <br/>
случаях: <br/>
- отрицательного баланса Лицевого счёта Клиента;</span></p>

            <p className="MsoNormal"><span>-
нарушения Клиентом технических ограничений установленных выбранным <br/>
тарифом;</span></p>

            <p className="MsoNormal"><span>-
совершения действий, которые явно указывают, что Клиент не намерен или не <br/>
способен соблюдать условия Договора.</span></p>

            <p className="MsoNormal"><span>6.4.
Клиент вправе: </span></p>

            <p className="MsoNormal"><span>6.4.1.
Прекратить пользоваться Сервисом без предварительного уведомления <br/>
Исполнителя об этом.</span></p>

            <p className="MsoNormal"><span>6.4.2.
Оставлять отзывы о Сервисе в информационной базе <br/>
Сервиса, при этом все отзывы, оставленные Клиентом, проверяются Исполнителем и
при <br/>
необходимости могут быть удалены. </span></p>

            <p className="MsoNormal"><span>6.5.
Клиент не вправе: </span></p>

            <p className="MsoNormal"><span>6.5.1.
Вносить изменения и/или создавать производные работы на основе <br/>
программного обеспечения Исполнителя.</span></p>

            <p className="MsoNormal"><span>6.5.2.
Разбирать программу на составляющие коды, <span className="SpellE">декомпилировать</span>
и/или иным <br/>
образом пытаться получить исходный код программного обеспечения или любой его
части. <br/>
6.5.3. Использовать какие-либо фирменные наименования, товарные знаки, знаки <br/>
обслуживания, логотипы, доменные имена, бренды или иные отличительные знаки
Сервиса и Исполнителя.</span></p>

            <p className="MsoNormal"><span>6.6.
Стороны несут ответственность за неисполнение или ненадлежащее исполнение <br/>
своих обязательств по Договору в соответствии с Договором и законодательством
РФ.</span></p>

            <p className="MsoNormal"><span>6.7.
<span className="GramE">Исполнитель не несет какой-либо ответственности перед
Клиентом и не возмещает Клиенту какие-либо убытки и/или упущенную выгоду, в т.
ч. понесенную Клиентом и/или третьими лицами, за недоступность Сервера или
услуги, возникшие по следующим причинам: неправильной настройки оборудования,
неработоспособности или гибели оборудования; отсутствия подключения, либо сбои
в подключении к сети Интернет;</span> недоступность каналов связи; разглашения,
либо потери Клиентом, либо кражи у Клиента учетных данных.</span></p>

            <p className="MsoNormal"><span>6.8.
Исполнитель не несет ответственности за качество оказания услуг в период
пробного использования Клиентом Сервиса, указанный в п.5.3. Настоящей Оферты.</span></p>

            <p className="MsoNormal"><span>&nbsp;</span></p>

            <p className="MsoNormal text-center"><b><span>7.
Персональные данные и их защита</span></b></p>

            <p className="MsoNormal"><span>7.1.
Клиент дает безоговорочное согласие на обработку, передачу и хранение Исполнителем
информации, содержащей персональные данные Клиента, и согласен с тем, что они <span className="GramE">будут храниться у Исполнителя и</span> будут обрабатываться
исключительно для целей исполнения настоящего Договора, в соответствии с
Федеральным законом № 152-ФЗ от 27.07.2006 г. «О персональных данных».</span></p>

            <p className="MsoNormal"><span>7.2.
Исполнитель обязуется обрабатывать Персональные данные строго в соответствии с
принципами и правилами обработки персональных данных, предусмотренными
законодательством РФ. <span className="GramE">Клиент передает Исполнителю
персональные данные с целью оказания Услуг, указанных в настоящей оферте и
поручает Исполнителю их обработку, совершаемую с использованием средств
автоматизации или без использования таких средств, включая сбор, запись,
систематизацию, накопление, хранение, уточнение (обновление, изменение),
извлечение, использование, передачу (предоставление, доступ), обезличивание,
блокирование, удаление, уничтожение персональных данных.</span></span></p>

            <p className="MsoNormal"><span>7.3.
Клиент соглашается, что несет ответственность за сохранение конфиденциальности
паролей, связанных с любым аккаунтом, <span className="GramE">используемыми</span>
Клиентом для доступа к Услуге. Следовательно, Клиент соглашается, что несет
исключительную ответственность перед Исполнителем за все действия, которые
совершены при использовании своего аккаунта. Клиент соглашается незамедлительно
уведомить Исполнителя, если станет известно о любом несанкционированном
использовании своего пароля и аккаунта.</span></p>

            <p className="MsoNormal"><span>7.4.
Исполнитель обеспечивает соблюдение всех требований законодательства и иных
нормативно-­правовых актов РФ в отношении обработки и передачи персональных
данных физических лиц, полученных от Клиента.</span></p>

            <p className="MsoNormal"><span>7.5.
Клиент обязан выполнить все требования законодательства и иных
нормативно-правовых актов в отношении обработки и передачи Исполнителю
персональных данных физических лиц, которые передаются Исполнителю. Все
персональные данные, полученные Исполнителем от Клиента, подразумеваются <span className="GramE">полученными</span> на законном основании с соблюдением положений
законодательства о персональных данных. Клиент признает, что нарушение им
порядка обращения персональных данных не налагает на Исполнителя какой-либо
ответственности и лишает права Клиента предъявлять претензии к Исполнителю в
отношении нарушения режима персональных данных, если только такое нарушение не
было виновно совершено Исполнителем при условии соблюдения Клиентом всех
требований действующего законодательства об обращении персональных данных.</span></p>

            <p className="MsoNormal"><span>7.6.
Клиент дает безоговорочное согласие на использование<span>&nbsp; </span><span className="SpellE">итернет</span>-сайтом
Сервиса </span><a href="https://aerotur.aero/"><span>https://</span></a><span><span>&nbsp; </span>идентификационных файлов <span
                className="SpellE">cookie</span></span><span lang="EN-US">s</span><span> и схожих технологий. Исполнитель
использует информацию, собираемую файлами <span className="SpellE">cookies</span> и
иными технологиями, для понимания и анализа тенденций, администрирования Сайта,
изучения поведения пользователей на сайте, и не рассматривает такую информацию
в качестве <span className="GramE">персональной</span>.<span>&nbsp; </span></span></p>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <p className="MsoNormal"><a><span className="markedcontent"><span>&nbsp;</span></span></a></p>

            <p className="MsoNormal text-center"><span><b><span>8.
Расторжение Договора</span></b></span><b><span></span></b></p>

            <p className="MsoNormal"><span>8.1.
Настоящий <span className="GramE">Договор</span> может быть расторгнут по инициативе
любой Стороны.</span></p>

            <p className="MsoNormal"><span>8.2.
О расторжении Договора Сторона, инициирующая такое расторжение, уведомляет
письменно другую Сторону за 10 (Десять) дней до предполагаемой даты расторжения</span><span> </span><span>Договора.</span></p>

            <p className="MsoNormal"><span>8.4.
При наличии задолженности Клиента по оплате услуг, оказанных Исполнителем, Исполнитель
вправе расторгнуть договор в одностороннем порядке без письменного уведомления
Клиента <span>&nbsp;</span>о расторжении договора.</span></p>

            <p className="MsoNormal"><span>8.3.
В случае расторжения Договора, оказание услуг Исполнителем прекращается; информация
о состоянии работоспособности оборудования, учетная запись и прочая информация
Клиента удаляется с сервера. </span></p>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <p className="MsoNormal"><a><span>&nbsp;</span></a></p>

            <p className="MsoNormal text-center"><span><b><span>9.
Прочие условия</span></b></span><b><span></span></b></p>

            <p className="MsoNormal"><span>9.1.
Данная Оферта является официальным документом и публикуется в информационной
базе Сервиса по адресу: </span><a href="http://www.tcenter.ru/tariffs/home"><span>http://
</span></a><span></span></p>

            <p className="MsoNormal"><span>9.2.
Договор подлежит толкованию согласно законодательству Российской Федерации. По
вопросам, неурегулированным Офертой, Стороны руководствуются действующим
законодательством РФ.</span></p>

            <p className="MsoNormal"><span>9.3.
Договор вступает в силу в момент принятия Клиентом условий данной Оферты и действует
в течение 1 (одного) года. Договор автоматически продляется на тех же условиях
на следующий календарный год, в случае если Клиент продолжает использовать
Сервис, внося при этом в установленный Договором срок абонентскую плату.
Количество пролонгаций не ограничено. </span></p>

            <p className="MsoNormal"><span>9.4.
В случае возникновения разногласий, Клиент направляет Исполнителю <span>&nbsp;</span>претензию в письменной форме по адресу:_____________________.
Срок рассмотрения Исполнителем претензии Клиента составляет не более 30
(тридцати) календарных дней с момента получения претензии. В случае
невозможности разрешения разногласий путём переговоров они подлежат рассмотрению
в судебном порядке в соответствии с действующим законодательством.</span></p>

            <p className="MsoNormal"><span>9.5.
Обращение Клиента, в случае возникновения сбоев в работе Сервиса,
(неудовлетворительного качества, перерывов и т.п.) и для получения
информационно-справочных услуг Оператора принимаются в службе поддержки по
номеру:________ или по электронной почте по адресу: _______________.</span></p>

            <p className="MsoNormal"><span>9.6.
Местом заключения Договора признаётся местонахождение Исполнителя.</span></p>

            <p className="MsoNormal"><span>&nbsp;</span></p>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <p className="MsoNormal text-center"><a><b><span>10.
Реквизиты Исполнителя </span></b></a><b><span></span></b></p>

            <p className="MsoNormal"><span>&nbsp;</span></p>

        </div>
    )
}
