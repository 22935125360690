import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {groupAPI} from '../api/API'
import {GroupType, SelectOption} from '../Types/Types'
import {limitOptionsSelect} from '../helpers/tableHelper'
import {asyncCreator} from './appReducer'

export type GroupsFilter = {
    limit: SelectOption<number>
    offset: number
}
type InitialStateType = {
    groups: Array<GroupType> | null
    group: GroupType | null
    filter: GroupsFilter
    groupFilter: GroupsFilter
}

const initialState: InitialStateType = {
    groups: null,
    group: null,
    filter: {
        limit: limitOptionsSelect[0],
        offset: 0,
    },
    groupFilter: {
        limit: limitOptionsSelect[0],
        offset: 0,
    },
}

export const groupsReducer = createSlice({
    name: 'groupsReducer',
    initialState,
    reducers: {
        setGroups(state, {payload}) {
            state.groups = payload
        },
        setGroup(state, {payload}) {
            state.group = payload
        },
        setGroupsFilter(state, {payload}: PayloadAction<any>) {
            state.filter = payload
        },
        setGroupFilter(state, {payload}: PayloadAction<any>) {
            state.groupFilter = payload
        },
    }
})

export const {setGroups, setGroup, setGroupsFilter, setGroupFilter} = groupsReducer.actions

export default groupsReducer.reducer

export const getGroups = asyncCreator<{ controller: any }>('groupsReducer/getGroups', groupAPI.getGroups, setGroups)
export const getGroup = asyncCreator<{ id: string }>('groupsReducer/getGroup', groupAPI.getGroup, setGroup)
export const createGroup = asyncCreator<CreateGroupType>('groupsReducer/createGroup', groupAPI.createGroup, null, 'Группа добавлена')
export const editGroup = asyncCreator<EditGroupType>('groupsReducer/editGroup', groupAPI.editGroup, null, 'Изменения сохранены')
export const deleteGroup = asyncCreator<{ id: string }>('groupsReducer/deleteGroup', groupAPI.deleteGroup, null, 'Группа удалена')

type CreateGroupType = {
    name: string
    cityId: number | null
    cityName: string | null
}

type EditGroupType = {
    id: string
} & CreateGroupType
