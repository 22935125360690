import React, {memo} from 'react'
import {useSelector} from 'react-redux'
import {StateType} from '../../store/store'

type PropsType = {}
export const Footer: React.FC<PropsType> = memo(() => {
    const statistics = useSelector((state: StateType) => state.appReducer.statistics)

    return (
        <footer id="footerID" className="border-top p-3 footer-light bg-light mt-4">
            <div className="container d-flex flex-wrap justify-content-between align-items-center">
                <div className="col-md-6 d-flex flex-column">
                    <span className="text-muted">© Система мониторинга 2022</span>
                    <span className="text-muted">Всего обслуживается объектов: {statistics?.kol_obj}, устройств: {statistics?.kol_obor}</span>
                </div>
                <ul className="nav col-md-6 justify-content-end list-unstyled d-flex">
                    <li>Разработано <a target="_blank" rel="noreferrer" href="https://distsystems.ru">Дистанционные Системы</a></li>
                </ul>
            </div>
        </footer>
    )
})
