import React, {memo, useState} from 'react'
import s from './myInput.module.scss'
import {IoCloseSharp, IoEyeOffOutline, IoEyeOutline} from 'react-icons/all'

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    value: string
    label: string | any
    name?: string
    type?: string
    isPassword?: boolean
    clear?: () => void
    isInvalid?: string | boolean
    disabled?: boolean
}

export const MyInput: React.FC<Props> = memo(({value, label, type, clear, isInvalid, isPassword, disabled, ...props}) => {
    const [showPass, setShowPass] = useState(false)

    return (
        <div className={s.wrapper}>
            <div className={`${s.container} ${isInvalid && s.inputError} ${disabled && s.disableContainer}`}>
                <input value={value}
                       placeholder={label}
                       type={isPassword ? showPass ? 'text' : 'password' : type || 'text'}
                       className={`${s.input} ${isPassword && showPass ? 'showPassword' : 'hidePassword'}`}
                       disabled={disabled}
                       {...props}
                />
                {
                    !disabled ? <div className={s.iconsView}>
                            {isPassword && <div className={s.icon} style={clear && {marginRight: 0}} onClick={() => setShowPass(!showPass)}>
                                {showPass ? <IoEyeOffOutline size={18}/> : <IoEyeOutline size={18}/>}
                            </div>}
                            {clear && <div className={s.icon} onClick={() => clear()}>
                                <IoCloseSharp size={18}/>
                            </div>}
                        </div>
                        : null
                }
                <label className={s.label}>{label}</label>
            </div>
            {isInvalid && <div className={s.errorText}>{isInvalid}</div>}
        </div>
    )
})
