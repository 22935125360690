import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import s from './mySelect.module.scss'
import {IoCloseSharp, IoIosArrowDown, IoIosArrowUp} from 'react-icons/all'
import {useWindowHeight} from '@react-hook/window-size'

type MyOption = {
    label: string
    value: string | number
}

interface Props {
    value: MyOption | null
    label: string
    clearable?: boolean
    search?: boolean
    error?: string | boolean
    options: Array<MyOption> | null
    onChange: (option: MyOption | null) => void
}

export const MySelect: React.FC<Props> = memo(({value, label, clearable, error, options, onChange, search}) => {
    const [text, setText] = useState('')
    const [open, setOpen] = useState(false)
    const [focusInput, setFocusInput] = useState(false)
    const [maxHeight, setMaxHeight] = useState(0)
    const [containerHeight, setContainerHeight] = useState(53)
    const windowHeight = useWindowHeight()
    const refWrapper = useRef<HTMLDivElement>(null)
    const refContainer = useRef<HTMLDivElement>(null)
    const refOptions = useRef<HTMLDivElement>(null)
    const refInput = useRef<HTMLInputElement>(null)

    const selectHandler = useCallback((option: MyOption | null, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        onChange(option)
        setOpen(false)
    }, [onChange])

    useEffect(() => {
        if (open && windowHeight && refOptions?.current && refWrapper?.current && refContainer?.current) {
            setContainerHeight(refContainer.current.getBoundingClientRect().height)
            let height
            const max = 248
            const position = refWrapper.current.offsetTop + refOptions.current.offsetTop + max

            if (windowHeight - position < 0) {
                height = max + (windowHeight - position) - 10
            } else height = max

            setMaxHeight(height)
        }
    }, [open, windowHeight, refOptions])

    const optionsMap = useMemo(() => options ? [...options].filter(i => i.label.toLowerCase().includes(text.toLowerCase())).map(i => (
        <div key={i.value}
             className={`${s.option} ${s.unselectedText} ${value && value.value === i.value && s.select}`}
             onClick={(e) => selectHandler(i, e)}
        >
            {i.label}
        </div>
    )) : [], [options, selectHandler, value, text])

    return (
        <div className={s.wrapper}
             ref={refWrapper}
             tabIndex={0}
             onBlur={() => {
                 if (!search) setOpen(false)
             }}
        >
            <div className={`${s.container} ${open && s.open}`}
                 onClick={() => {
                     if (search) {
                         if (!open) {
                             refInput.current?.focus()
                         } else {
                             refInput.current?.blur()
                         }
                     }
                     setOpen(!open)
                 }}
                 ref={refContainer}
            >
                {
                    search
                        ? <input value={focusInput ? text : value?.label}
                                 onChange={(e) => setText(e.target.value)}
                                 placeholder="Поиск"
                                 className={`${s.input} ${s.value}`}
                                 onFocus={() => setFocusInput(true)}
                                 onBlur={() => {
                                     setTimeout(() => {
                                         setOpen(false)
                                         setFocusInput(false)
                                         setText('')
                                     }, 100)
                                 }}
                                 ref={refInput}
                        />
                        : <div className={`${s.value} ${s.unselectedText}`}>{value?.label || ''}</div>
                }
                <div className={`${s.label} ${s.unselectedText} ${value && value.value && s.shift}`}>{label}</div>
                <div className={s.iconsView}>
                    {clearable && <div className={s.icon} style={{marginRight: 0}} onClick={(e) => {
                        e.stopPropagation()
                        onChange(null)
                    }}>
                        <IoCloseSharp size={18}/>
                    </div>}
                    <div className={s.icon}>
                        {open ? <IoIosArrowUp size={18}/> : <IoIosArrowDown size={18}/>}
                    </div>
                </div>
            </div>
            {
                open
                    ?
                    <div className={s.options}
                         ref={refOptions}
                         style={{maxHeight: maxHeight, top: containerHeight + 9, minHeight: optionsMap?.length < 3 ? optionsMap.length * 31 : 93}}
                    >
                        {optionsMap?.length > 0 ? optionsMap : <div className={s.option}>Пусто</div>}
                    </div>
                    : null
            }
            {error && <div className={s.errorText}>{error}</div>}
        </div>
    )
})
