import React, {useEffect, useMemo, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {StateType} from '../../store/store'
import {usePagination} from '../../hooks/usePagination'
import Select from 'react-select'
import {limitOptionsSelect} from '../../helpers/tableHelper'
import {NavLink, useHistory, useLocation, useParams} from 'react-router-dom'
import {filterToString} from '../../helpers/filterToString'
import {filterFromString} from '../../helpers/filterFromString'
import deepEqual from 'deep-equal'
import {Helmet} from 'react-helmet-async'
import {priorityDecoder} from '../../helpers/priorityDecoder'
import {getDuration} from '../../helpers/getDuration'
import Moment from 'moment'
import {BackButton} from '../../components/BackButton/BackButton'
import {getDevice, getEvents, setDevice, setDeviceFilter, setEvents} from '../../store/deviceReducer'
import {Loading} from '../../components/Loading/Loading'
import {MyBreadcrumb} from '../../components/MyBreadcrumb/MyBreadcrumb'

export const Device = () => {
    const id = useParams<{ id: string }>().id
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const device = useSelector((state: StateType) => state.deviceReducer.device)
    const events = useSelector((state: StateType) => state.deviceReducer.events)
    const filter = useSelector((state: StateType) => state.deviceReducer.filter)
    const {CustomPagination} = usePagination(filter, events?.length, (value) => {
        dispatch(setDeviceFilter(value))
        history.push({search: filterToString(value)})
        dispatch(getEvents({id, controller}))
    })
    const controller = useMemo(() => new AbortController(), [])
    const timer = useRef<any>()

    useEffect(() => {
        if (events) {
            const filterString = filterFromString(location.search)
            if (filterString && !deepEqual(filterString, filter)) {
                dispatch(setDeviceFilter({...filterString}))
            } else if (location.search !== filterToString(filter)) {
                history.replace({search: filterToString(filter)})
            }
        }
    }, [dispatch, filter, history, location, events])

    useEffect(() => {
        if (id) {
            dispatch(getDevice({id, controller}))
            dispatch(getEvents({id, controller}))
            timer.current = setInterval(() => {
                dispatch(getDevice({id, controller, noLoading: true}))
                dispatch(getEvents({id, controller, noLoading: true}))
            }, 30000)
        }
    }, [dispatch, id, controller])

    useEffect(() => {
        return () => {
            dispatch(setDevice(null))
            dispatch(setEvents(null))
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, controller])

    const eventsData = useMemo(() => {
        if (events && device && device.lastchange) return [{value: device?.value, start_clock: device?.lastchange.toString(), end_clock: '0', duration: device?.duration}, ...events]
        else if (events && device && !device.lastchange) return [...events]
        else if (device && device.lastchange) return [{value: device?.value, start_clock: device?.lastchange.toString(), end_clock: '0', duration: device?.duration}]
        else return []
    }, [events, device])

    const eventsMap = useMemo(() => eventsData && [...eventsData].slice(filter.offset, filter.limit.value + filter.offset).map((i, index) => {

        return (
            <tr key={index + i.start_clock + i.end_clock} style={{backgroundColor: i.value ? '#ff8a8a' : '#96ff8d'}}>
                <td className="align-middle">{Moment.utc(parseInt(i.start_clock, 10) * 1000).format('DD.MM.YYYY HH:mm:ss')}</td>
                <td className="align-middle">{
                    i.end_clock !== '0'
                        ? Moment.utc(parseInt(i.end_clock, 10) * 1000).format('DD.MM.YYYY HH:mm:ss')
                        : Moment(+new Date()).format('DD.MM.YYYY HH:mm:ss')
                }
                </td>
                <td className="align-middle">{getDuration(i.duration || parseInt(i.end_clock, 10) - parseInt(i.start_clock, 10))}</td>
                <td className="align-middle">{i.value === 0 ? 'Да' : 'Нет'}</td>
            </tr>
        )
    }), [eventsData, filter])

    if (!device) return <Loading/>

    return (
        <div className="container">
            <MyBreadcrumb />
            <div className="backButton" onClick={() => history.goBack()}><BackButton/></div>
            <Helmet>
                <title>{device?.name}</title>
            </Helmet>
            <h2 className="mt-1 mb-1">{device?.name}</h2>
            {device?.port ? <div style={{fontSize: 16}}>Порт: {device.port}</div> : null}
            {device?.idAjaxDevice ? <div style={{fontSize: 16}}>ID оборудования Ajax Systems: {device.idAjaxDevice}</div> : null}
            <div style={{fontSize: 16}}>Статус: {device?.status === '1' ? 'Активировано' : 'Деактивировано'}</div>
            <div style={{fontSize: 16}}>Важность: {device?.priority && priorityDecoder(device.priority)}</div>
            <div style={{fontSize: 16}}>Состояние: {device?.value === 0 ? 'ОК' : 'ПРОБЛЕМА'}</div>
            <NavLink to={{pathname: `/formDevice/${device?.id}`}}
                     className="btn btn-primary mt-2"
            >
                Редактировать
            </NavLink>
            <hr className="mt-4 mb-3"/>
            <h2 className="mb-2">Статистика</h2>
            <div className="table-responsive " style={{flex: 1}}>
                <div className="selectBlock">
                    <Select className="searchSelect"
                            value={filter.limit}
                            onChange={(item) => history.replace({search: filterToString({...filter, limit: item, offset: 0})})}
                            options={limitOptionsSelect}
                            isSearchable={false}
                    />
                    <CustomPagination/>
                </div>
                <table className="table table-sm">
                    <thead>
                    <tr>
                        <th className="align-middle" scope="col">Начало периода</th>
                        <th className="align-middle" scope="col">Окончание периода</th>
                        <th className="align-middle" scope="col">Продолжительность периода</th>
                        <th className="align-middle" scope="col">Доступность оборудования</th>
                    </tr>
                    </thead>
                    <tbody>
                    {eventsMap}
                    </tbody>
                </table>
                <CustomPagination/>
            </div>
        </div>
    )
}
