import React, {memo, useMemo} from 'react'
import {Button, Form, Spinner} from 'react-bootstrap'
import {Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from 'react-redux'
import {StateType} from '../../store/store'
import {Formik} from 'formik'
import * as yup from 'yup'
import {login} from '../../store/appReducer'
import {useHistory} from 'react-router-dom'
import {MyInput} from '../../components/MyForms/MyInput'

const schema = yup.object().shape({
    login: yup.string().trim().email('Это не email').required('Введите email'),
    password: yup.string().required('Введите пароль')
})

export const Login = memo(() => {
    const dispatch = useDispatch()
    const history = useHistory()
    const status = useSelector((state: StateType) => state.appReducer.status)
    const controller = useMemo(() => new AbortController(), [])

    const initialForm = {
        login: '',
        password: '',
        isRemaining: true
    }

    return (
        <div className="text-center" style={{flex: 1, display: 'flex'}}>
            <Helmet>
                <title>Вход</title>
            </Helmet>
            <Formik
                validationSchema={schema}
                initialValues={initialForm}
                onSubmit={async (values) => {
                    const {payload}: any = await dispatch(login({login: values.login, password: values.password, isRemaining: values.isRemaining, controller}))
                    if (payload) history.push('/')
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      setFieldValue
                  }) => {

                    return (
                        <Form onSubmit={handleSubmit}
                              autoComplete="off"
                              className="formSignin"
                              style={{maxWidth: 330}}
                        >
                            <h1 className="mt-6 mb-4">Вход</h1>
                            <MyInput value={values.login}
                                     label="Электронная почта"
                                     name="login"
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     clear={() => {
                                         setFieldValue('login', '')
                                     }}
                                     isInvalid={touched.login && errors.login}
                            />
                            <MyInput value={values.password}
                                     label="Пароль"
                                     name="password"
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     clear={() => {
                                         setFieldValue('password', '')
                                     }}
                                     isInvalid={touched.password && errors.password}
                                     isPassword
                            />
                            <div className="checkbox mb-3 mt-3">
                                <label>
                                    <Form.Check type="checkbox"
                                                id="isRemaining"
                                                checked={values.isRemaining}
                                                onChange={handleChange}
                                                label="Запомнить меня"
                                    />
                                </label>
                            </div>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={status === 'loading' || isSubmitting}
                                    style={{marginTop: 10}}
                            >
                                {isSubmitting ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : 'Войти'}
                            </Button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
})
