import React from 'react'
import {Pagination} from 'react-bootstrap'

export const usePagination = (filter: any, total: number = 0, setFilter: (value: any) => void, extra?: Array<string>) => {
    const currentPage = extra ? filter[extra[1]] / filter[extra[0]]?.value + 1 : filter.offset / filter.limit?.value + 1
    const lastPage = extra ? total % filter[extra[0]]?.value === 0 ? total / filter[extra[0]]?.value : Math.floor(total / filter[extra[0]]?.value) + 1
        : total % filter.limit?.value === 0 ? total / filter.limit?.value : Math.floor(total / filter.limit?.value) + 1

    const onPageClick = (page: number) => {
        extra ? setFilter({...filter, [extra[1]]: filter[extra[0]]?.value * page - filter[extra[0]]?.value})
            : setFilter({...filter, offset: filter.limit?.value * page - filter.limit?.value})
    }

    if (total && currentPage > lastPage) onPageClick(currentPage - 1)

    const paginationItems: Array<any> = []
    const newItem = (value: number, onClick: (value: number) => void) => {
        return <Pagination.Item key={value}
                                active={value === currentPage}
                                onClick={() => onClick(value)}
        >
            {value}
        </Pagination.Item>
    }
    const newEllipsis = (value: number) => {
        return <Pagination.Ellipsis key={value + 'Ellipsis'} onClick={() => onPageClick(currentPage + value)}/>
    }

    if (total) {
        const range = 5
        if (lastPage > 1) {
            paginationItems.push(newItem(1, onPageClick))

            for (let page = 2; page < lastPage; page++) {
                if (page === currentPage - Math.floor(range / 2) - 1) {
                    paginationItems.push(newEllipsis(-3))
                    continue
                }

                if (page === currentPage + Math.floor(range / 2) + 1) {
                    paginationItems.push(newEllipsis(3))
                    continue
                }

                if (page > currentPage + Math.floor(range / 2) || page < currentPage - Math.floor(range / 2)) continue
                paginationItems.push(newItem(page, onPageClick))
            }

            paginationItems.push(newItem(lastPage, onPageClick))
        }
    }


    const CustomPagination = () => (
        <Pagination style={{padding: 0, marginBottom: 0}}>
            {paginationItems}
        </Pagination>
    )

    return {CustomPagination}
}
