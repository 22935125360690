import React, {memo, useCallback, useEffect, useState} from 'react'
import 'nprogress/nprogress.css'
import {useDispatch, useSelector} from 'react-redux'
import {StateType} from './store/store'
import NProgress from 'nprogress'
import {Navigation} from './components/Navigation/Navigation'
import {Footer} from './components/Footer/Footer'
import {Main} from './components/Main/Main'
import {ScrollToTop} from './components/ScrollToTop/ScrollToTop'
import {checkAuth, getStatistics, setGoToMain, setMapOnlyProblem} from './store/appReducer'
import {Loading} from './components/Loading/Loading'
import {NotifierGenerator} from './components/NotifierGenerator/NotifierGenerator'
import {useHistory} from "react-router-dom";

export const App = memo(() => {
    const dispatch = useDispatch()
    const history = useHistory()
    const status = useSelector((state: StateType) => state.appReducer.status)
    const token = useSelector((state: StateType) => state.appReducer.token)
    const goToMain = useSelector((state: StateType) => state.appReducer.goToMain)
    const profileType = useSelector((state: StateType) => state.appReducer.profileType)
    const [appReady, setAppReady] = useState(false)

    const prepare = useCallback(async () => {
        try {
            await dispatch(checkAuth())
            dispatch(getStatistics())

            const mapOnlyProblem = await localStorage.getItem('@mapOnlyProblem')
            dispatch(setMapOnlyProblem(mapOnlyProblem === 'true'))
        } finally {
            setAppReady(true)
        }
    }, [dispatch])

    useEffect(() => {
        prepare()
    }, [prepare])

    useEffect(() => {
        NProgress.configure({showSpinner: false})
        if (status === 'loading') {
            NProgress.start()
        } else {
            NProgress.done()
        }
        return () => {
            NProgress.done()
        }
    }, [status])

    if (!appReady) return <div className="d-flex flex-column min-vh-100"><Loading/></div>

    if (goToMain) {
        dispatch(setGoToMain(false))
        history.replace('/')
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            <Navigation token={token}
                        profileType={profileType}
            />
            <Main token={token}/>
            <Footer/>
            <ScrollToTop/>
            <NotifierGenerator/>
        </div>
    )
})
