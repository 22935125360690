import React, {memo, useEffect, useMemo, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from 'react-redux'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {getGroups} from '../../store/groupsReducer'
import {HostType} from '../../Types/Types'
import {createHost, deleteHost, editHost, getCities, getHost} from '../../store/hostsReducer'
import {StateType} from '../../store/store'
import {BackButton} from '../../components/BackButton/BackButton'
import {MyInput} from '../../components/MyForms/MyInput'
import {SetCoordinates} from './SetCoordinates'
import {Loading} from '../../components/Loading/Loading'
import {MySelect} from '../../components/MyForms/MySelect'

const schema = yup.object().shape({
    name: yup.string().required('Введите название').max(32, 'Максимальная длина 32 символа'),
    ip: yup.string().required('Введите ip адрес').matches(
        /^([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/,
        'Введите корректный IP-адрес'
    ).max(32, 'Максимальная длина 32 символа'),
    controlPort: yup.string().matches(
        /^([0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
        'Введите корректный порт'
    ).nullable(true),
    groupId: yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required('Выберите группу'),
    }),
    address: yup.string().max(1024, 'Максимальная длина 1024 символа'),
})

export const FormHost = memo(() => {
    const dispatch = useDispatch()
    const location = useLocation<{ params: HostType, groupId: string }>()
    const {id} = useParams<{ id: string }>()
    const params = location?.state?.params
    const groupId = location?.state?.groupId
    const history = useHistory()
    const host = useSelector((state: StateType) => state.hostsReducer.host)
    const groups = useSelector((state: StateType) => state.groupsReducer.groups)
    const cities = useSelector((state: StateType) => state.hostsReducer.cities)
    const [loading, setLoading] = useState(false)
    const controller = useMemo(() => new AbortController(), [])
    const [showModal, setShowModal] = useState(false)
    const [showMap, setShowMap] = useState(false)

    useEffect(() => {
        if (id) dispatch(getHost({id, controller}))
    }, [dispatch, id, controller])

    const groupsOptions = useMemo(() => groups && [...groups].map(({id, name}) => ({label: name, value: id})), [groups])

    useEffect(() => {
        dispatch(getGroups({controller}))

        return () => {
            controller.abort()
        }
    }, [dispatch, controller])

    useEffect(() => {
        if (!cities) dispatch(getCities({controller}))
    }, [dispatch, controller, cities])

    const formik = useFormik({
        initialValues: {
            name: host?.name || params?.name || '',
            ip: host?.ip || params?.ip || '',
            controlPort: host?.controlPort || params?.controlPort || '',
            address: host?.address || params?.address || '',
            groupId: {value: '', label: ''},
            lat: (host?.lat && parseFloat(host.lat)) || (params?.lat && parseFloat(params.lat)) || null,
            lon: (host?.lon && parseFloat(host.lon)) || (params?.lon && parseFloat(params.lon)) || null,
            https: host?.https || params?.https || false,
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            await onSubmit(values)
        }
    })
    const {values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, setFieldValue, validateForm, isValid, setTouched} = formik

    const onSubmit = async (values: any, isContinue = false) => {
        await setTouched(values)
        await validateForm(values)
        if (isValid) {
            if (values.groupId) {
                const params = {
                    id: id,
                    name: values.name,
                    ip: values.ip,
                    groupId: values.groupId.value,
                    controlPort: values.controlPort,
                    address: values.address,
                    lat: values.lat,
                    lon: values.lon,
                    https: values.https
                }
                if (id) {
                    try {
                        setLoading(true)
                        const {payload}: any = await dispatch(editHost({...params, controller}))
                        if (payload === true) {
                            if (isContinue) history.replace('/formHost', {params})
                            else history.goBack()
                        }
                    } finally {
                        setLoading(false)
                    }
                } else {
                    try {
                        setLoading(true)
                        const {payload}: any = await dispatch(createHost({...params, controller}))
                        if (payload === true && !isContinue) {
                            history.goBack()
                        }
                    } finally {
                        setLoading(false)
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (groupsOptions && !values?.groupId?.value) {
            if (groupId) {
                const opt = groupsOptions.find(i => i.value === groupId)
                setFieldValue('groupId', opt)
            } else if (host) {
                const opt = groupsOptions.find(i => i.value === host.groupId)
                setFieldValue('groupId', opt)
            } else if (params) {
                const opt = groupsOptions.find(i => i.value === params.groupId)
                setFieldValue('groupId', opt)
            } else setFieldValue('groupId', groupsOptions[0])
        }
    }, [values, params, groupsOptions, setFieldValue, groupId, host])

    const deleteHandler = async () => {
        try {
            setLoading(true)
            const {payload}: any = await dispatch(deleteHost({id, controller}))
            if (payload) {
                history.goBack()
                history.goBack()
            }
        } finally {
            setLoading(false)
        }
    }

    if (showMap && cities) return <SetCoordinates
        cityId={groups?.find(i => i.id === values.groupId.value)?.cityId}
        lat={values.lat}
        lon={values.lon}
        cities={cities}
        address={values.address}
        name={values.name}
        updateLocation={(lat: number | null, lon: number | null) => {
            setFieldValue('lat', lat)
            setFieldValue('lon', lon)
        }}
        setShowMap={setShowMap}
    />

    if ((id && !host) || !groups || !cities) return <Loading/>

    return (
        <div className="text-center" style={{flex: 1, display: 'flex'}}>
            <Helmet>
                <title>{id ? 'Изменение объекта' : 'Добавление объекта'}</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
            >
                <div className="backButtonForm" onClick={() => history.goBack()}><BackButton/></div>
                <h1 className="mt-6 mb-4">{id ? 'Изменение объекта' : 'Добавление объекта'}</h1>
                <div className="formInputs">
                    <MyInput value={values.name}
                             label="Название"
                             name="name"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => {
                                 setFieldValue('name', '')
                             }}
                             isInvalid={touched.name && errors.name}
                    />
                    <MyInput value={values.ip}
                             label="IP-адрес"
                             name="ip"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => {
                                 setFieldValue('ip', '')
                             }}
                             isInvalid={touched.ip && errors.ip}
                    />
                    <MyInput value={values.controlPort}
                             label="Порт управления"
                             name="controlPort"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => {
                                 setFieldValue('controlPort', '')
                             }}
                             isInvalid={touched.controlPort && errors.controlPort}
                    />
                    <div className="checkbox mb-3 mt-3">
                        <label>
                            <Form.Check type="checkbox"
                                        id="https"
                                        checked={values.https}
                                        onChange={handleChange}
                                        label="HTTPS"
                            />
                        </label>
                    </div>
                    <MyInput value={values.address}
                             label="Физический адрес"
                             name="address"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => {
                                 setFieldValue('address', '')
                             }}
                             isInvalid={touched.address && errors.address}
                    />
                    <MySelect value={values.groupId}
                              label="Группа"
                              options={groupsOptions}
                              onChange={(item) => setFieldValue('groupId', item)}
                              error={touched.groupId && !!errors.groupId && errors.groupId.value}
                    />
                    <Button variant="link"
                            style={{padding: 0}}
                            onClick={() => setShowMap(true)}
                    >
                        {`${values.lat && values.lon ? 'Изменить' : 'Указать'} местоположение`}
                    </Button>
                </div>
                <div className="form-buttons-block">
                    <Button variant="primary"
                            type="submit"
                            disabled={loading || isSubmitting}
                    >
                        Сохранить
                    </Button>
                    <Button variant="primary"
                            disabled={loading || isSubmitting}
                            onClick={() => onSubmit(values, true)}
                    >
                        Продолжить
                    </Button>
                    <Button variant="outline-secondary"
                            onClick={() => history.goBack()}
                    >
                        Отмена
                    </Button>
                    {
                        id
                            ? <Button variant="outline-danger"
                                      onClick={() => setShowModal(true)}
                                      disabled={loading || isSubmitting}
                            >
                                Удалить
                            </Button>
                            : null
                    }
                </div>
            </Form>
            {
                showModal
                    ? <Modal show
                             onHide={() => setShowModal(false)}
                    >
                        <Modal.Body style={{textAlign: 'center'}}>
                            Вы уверены что хотите удалить объект?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={() => setShowModal(false)}>
                                Нет
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={deleteHandler}
                                    disabled={loading}
                            >
                                Да
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
        </div>
    )
})
