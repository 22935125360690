import React, {memo} from 'react'
import {Route, Switch} from 'react-router-dom'
import {Default} from '../../pages/Default/Default'
import {Instruction} from '../../pages/Instruction/Instruction'
import {Agreement} from '../../pages/Agreement/Agreement'
import {Tariffs} from '../../pages/Tariffs/Tariffs'
import {Login} from '../../pages/Login/Login'
import {Registration} from '../../pages/Registration/Registration'
import {Problems} from '../../pages/Problems/Problems'
import {Groups} from '../../pages/Groups/Groups'
import {Hosts} from '../../pages/Hosts/Hosts'
import {Host} from '../../pages/Hosts/Host'
import {Group} from '../../pages/Groups/Group'
import {Device} from '../../pages/Device/Device'
import {FormGroup} from '../../pages/Groups/FormGroup'
import {FormDevice} from '../../pages/Device/FormDevice'
import {Analytics} from '../../pages/Analytics/Analytics'
import {Bills} from '../../pages/Bills/Bills'
import {BadRequest} from '../../pages/BadRequest/BadRequest'
import {Profile} from '../../pages/Profile/Profile'
import {CompleteRegistration} from '../../pages/Registration/CompleteRegistration'
import {ConfirmTelegram} from '../../pages/Profile/ConfirmTelegram'
import {Reports} from '../../pages/Reports/Reports'
import {FormHost} from '../../pages/Hosts/FormHost'
import {MapComponent} from '../../pages/MapComponent/MapComponent'
import {Problem} from '../../pages/Problems/Problem'
import {FormAjaxSystems} from "../../pages/AjaxSystems/FormAjaxSystems";

type PropsType = {
    token: string | null
}
export const Main: React.FC<PropsType> = memo(({token}) => {
    return (
        <main style={{flex: 1, display: 'flex'}}>
            {
                token
                    ? <Switch>
                        <Route exact path="/" component={Default}/>
                        <Route exact path="/instruction" component={Instruction}/>
                        <Route exact path="/agreement" component={Agreement}/>
                        <Route exact path="/tariffs" component={Tariffs}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/problems" component={Problems}/>
                        <Route exact path="/problems/:id" component={Problem}/>
                        <Route exact path="/groups" component={Groups}/>
                        <Route exact path="/groups/:id" component={Group}/>
                        <Route exact path="/formGroup/:id?" component={FormGroup}/>
                        <Route exact path="/hosts" component={Hosts}/>
                        <Route exact path="/hosts/:id" component={Host}/>
                        <Route exact path="/formHost/:id?" component={FormHost}/>
                        <Route exact path="/devices/:id" component={Device}/>
                        <Route exact path="/formDevice/:id?" component={FormDevice}/>
                        <Route exact path="/analytics" component={Analytics}/>
                        <Route exact path="/bills" component={Bills}/>
                        <Route exact path="/reports" component={Reports}/>
                        <Route exact path="/formAjaxSystems/:id?" component={FormAjaxSystems}/>
                        <Route exact path="/profile" component={Profile}/>
                        <Route exact path="/map" component={MapComponent}/>
                        <Route exact path="/confirmTelegram" component={ConfirmTelegram}/>
                        <Route path="*" component={BadRequest}/>
                    </Switch>
                    : <Switch>
                        <Route exact path="/" component={Default}/>
                        <Route exact path="/instruction" component={Instruction}/>
                        <Route exact path="/agreement" component={Agreement}/>
                        <Route exact path="/tariffs" component={Tariffs}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/registration" component={Registration}/>
                        <Route exact path="/bills" component={Bills}/>
                        <Route exact path="/reports" component={Reports}/>
                        <Route exact path="/completeRegistration/:token" component={CompleteRegistration}/>
                        <Route path="*" component={BadRequest}/>
                    </Switch>
            }
        </main>
    )
})
