import moment from 'moment'
import {GroupType} from '../Types/Types'

export const filterFromString = (search: string, extra?: any): { [key: string]: any } | null => {
    const filter: { [key: string]: any } = {}
    const replaced = search.replace('?', '')
    const splited = replaced.split('&')

    splited.forEach(i => {
        const splited = i.split('=')
        const key = splited[0]
        const value = splited[1]

        if (key === undefined || key === 'undefined' || value === undefined || value === 'undefined' || value === '') {
            return
        } else if (key === 'searchName') {
            filter[key] = decodeURI(value)
        } else if (key.toLowerCase().includes('limit')) filter[key] = {value: parseInt(value, 10), label: value}
        else if (key.toLowerCase().includes('offset')) filter[key] = parseInt(value, 10)
        else if (key === 'idGroup' && extra?.groups) {
            const current = extra.groups.find((b: GroupType) => b.id === value)
            if (current) filter[key] = {label: current.name, value: current.id}
        } else if (key === 'dateFrom' || key === 'dateTo') {
            filter[key] = new Date(moment(value.replace('%20', ' '), 'YYYY.MM.DD HH:mm:ss').toString()).toString()
        } else filter[key] = value
    })
    if (Object.entries(filter).length > 0) return filter
    else return null
}
