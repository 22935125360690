import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {StateType} from '../../store/store'
import {priorityColorDefinitionText} from '../../helpers/priorityColorDefinition'
import {usePagination} from '../../hooks/usePagination'
import Select from 'react-select'
import {limitOptionsSelect} from '../../helpers/tableHelper'
import {deleteGroup, getGroups, setGroupsFilter} from '../../store/groupsReducer'
import {NavLink, useHistory, useLocation} from 'react-router-dom'
import {filterToString} from '../../helpers/filterToString'
import {filterFromString} from '../../helpers/filterFromString'
import deepEqual from 'deep-equal'
import {Helmet} from 'react-helmet-async'
import {HostType} from '../../Types/Types'
import {multipleExist} from '../../helpers/multipleExist'
import {Button, Modal} from 'react-bootstrap'
import {Loading} from '../../components/Loading/Loading'

export const Groups = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const profileType = useSelector((state: StateType) => state.appReducer.profileType)
    const groups = useSelector((state: StateType) => state.groupsReducer.groups)
    const filter = useSelector((state: StateType) => state.groupsReducer.filter)
    const controller = useMemo(() => new AbortController(), [])
    const [checked, setChecked] = useState<Array<string>>([])
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const {CustomPagination} = usePagination(filter, groups?.length, (value) => {
        dispatch(setGroupsFilter(value))
        history.push({search: filterToString(value)})
        dispatch(getGroups({controller}))
        setChecked([])
    })
    const timer = useRef<any>()

    useEffect(() => {
        if (groups) {
            const filterString = filterFromString(location.search)
            if (filterString && !deepEqual(filterString, filter)) {
                dispatch(setGroupsFilter({...filterString}))
            } else if (location.search !== filterToString(filter)) {
                history.replace({search: filterToString(filter)})
            }
        }
    }, [dispatch, filter, history, location, groups])

    useEffect(() => {
        dispatch(getGroups({controller}))
        timer.current = setInterval(() => dispatch(getGroups({controller, noLoading: true})), 30000)

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, controller])

    const allIds = useMemo(() => groups?.slice(filter.offset, filter.limit.value + filter.offset).map(i => i.id) || [], [groups, filter])

    const checkAll = useCallback(() => {
        if (groups) {
            let ids = [...checked]
            const limitGroups = groups.slice(filter.offset, filter.limit.value + filter.offset).filter(i => i.name !== 'Основная группа').map(i => i.id)
            if (multipleExist(ids, limitGroups)) {
                limitGroups.forEach(i => ids = ids.filter(h => h !== i))
            } else {
                limitGroups.forEach(i => {
                    if (!ids.includes(i)) ids = [...ids, i]
                })
            }
            setChecked(ids)
        }
    }, [groups, filter, checked])

    const handleCheck = useCallback((value: string) => {
        if (checked.includes(value)) setChecked(checked.filter(i => i !== value))
        else setChecked([...checked, value])
    }, [checked])

    const groupsMap = useMemo(() => groups && [...groups].slice(filter.offset, filter.limit.value + filter.offset).map(i => {
        // let priority = 0
        const hosts: Array<HostType> = []
        const hostsWithProblem: Array<HostType> = []

        i.hosts?.forEach(host => {
            hosts.push(host)
            host.items?.forEach(item => {
                if (item.value === 1 && !hostsWithProblem.find(h => h.id === host.id)) {
                    hostsWithProblem.push(host)
                    // if (item.priority > priority) {
                    // priority = item.priority
                    // }
                }
            })
        })

        return (
            // <tr key={i.id} style={{backgroundColor: priorityColorDefinition(priority)}}>
            <tr key={i.id}>
                <td className="align-middle">
                    {(profileType === 1 || profileType === 2) ? <input className="table-checkbox"
                                                                       onChange={() => handleCheck(i.id)}
                                                                       checked={checked.includes(i.id)}
                                                                       value={i.id}
                                                                       type="checkbox"
                                                                       disabled={i.name === 'Основная группа'}
                    /> : null}
                </td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/groups/${i.id}`}>{i.name}</NavLink></td>
                <td className="align-middle">{hosts.map((i, index) => {
                    const lastItem = hosts.length === index + 1
                    let priority = 0
                    i.items?.forEach(item => {
                        if (item.value === 1) {
                            if (item.priority > priority) {
                                priority = item.priority
                            }
                        }
                    })
                    return <span key={i.id}><NavLink className="custom-link-table-item" to={`/hosts/${i.id}`} style={{color: priorityColorDefinitionText(priority)}}>{i.name}</NavLink>{!lastItem ? ', ' : ''}</span>
                })}
                </td>
                <td className="align-middle">{hostsWithProblem.map((i, index) => {
                    const lastItem = hostsWithProblem.length === index + 1
                    let priority = 0
                    i.items?.forEach(item => {
                        if (item.value === 1) {
                            if (item.priority > priority) {
                                priority = item.priority
                            }
                        }
                    })
                    return <span key={i.id}><NavLink className="custom-link-table-item" to={`/hosts/${i.id}`} style={{color: priorityColorDefinitionText(priority)}}>{i.name}</NavLink>{!lastItem ? ', ' : ''}</span>
                })}
                </td>
            </tr>
        )
    }), [groups, filter, handleCheck, checked, profileType])

    const deleteHandler = async () => {
        try {
            setLoading(true)
            const {payload}: any = await dispatch(deleteGroup({id: checked.join(';'), controller}))
            if (payload) {
                dispatch(getGroups({controller}))
                setShowModal(false)
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="table-responsive container" style={{flex: 1}}>
            <Helmet>
                <title>Группы объектов</title>
            </Helmet>
            <h2 className="mb-2">Группы объектов</h2>
            {(profileType === 1 || profileType === 2) ? <NavLink to="/formGroup"
                                                                 className="btn btn-primary mt-2"
            >
                Добавить группу
            </NavLink> : null}
            <div className="selectBlock">
                <Select className="searchSelect"
                        value={filter.limit}
                        onChange={(item) => history.replace({search: filterToString({...filter, limit: item, offset: 0})})}
                        options={limitOptionsSelect}
                        isSearchable={false}
                />
                <CustomPagination/>
            </div>
            <table className="table table-sm">
                <thead>
                <tr>
                    <th className="align-middle" scope="col">
                        {(profileType === 1 || profileType === 2) ? <input className="table-checkbox"
                                                                           checked={checked.length > 0 && multipleExist(checked, allIds)}
                                                                           onChange={checkAll}
                                                                           type="checkbox"
                        /> : null}
                    </th>
                    <th className="align-middle" scope="col">Название</th>
                    <th className="align-middle" scope="col">Объекты</th>
                    <th className="align-middle" scope="col">Объекты с проблемами</th>
                </tr>
                </thead>
                <tbody>
                {groups ? groupsMap : <tr>
                    <td colSpan={4}><Loading/></td>
                </tr>}
                </tbody>
            </table>
            <div style={{display: 'flex'}}>
                {(profileType === 1 || profileType === 2) ? <input type="submit"
                                                                   className="btn btn-danger mb-1"
                                                                   style={{marginRight: 10}}
                                                                   value="Удалить"
                                                                   onClick={() => setShowModal(true)}
                                                                   disabled={loading || checked.length === 0}
                /> : null}
                <CustomPagination/>
            </div>
            {
                showModal
                    ? <Modal show
                             onHide={() => setShowModal(false)}
                    >
                        <Modal.Body style={{textAlign: 'center'}}>
                            {`Вы уверены что хотите удалить ${checked.length > 1 ? 'группы' : 'группу'}?`}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={() => setShowModal(false)}>
                                Нет
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={deleteHandler}
                                    disabled={loading}
                            >
                                Да
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
        </div>
    )
}
