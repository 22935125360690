import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {StateType} from '../../store/store'
import {priorityColorDefinitionBackground} from '../../helpers/priorityColorDefinition'
import {usePagination} from '../../hooks/usePagination'
import Select from 'react-select'
import {limitOptionsSelect} from '../../helpers/tableHelper'
import {NavLink, useHistory, useLocation, useParams} from 'react-router-dom'
import {filterToString} from '../../helpers/filterToString'
import {filterFromString} from '../../helpers/filterFromString'
import deepEqual from 'deep-equal'
import {Helmet} from 'react-helmet-async'
import {BackButton} from '../../components/BackButton/BackButton'
import {getGroup, setGroup, setGroupFilter} from '../../store/groupsReducer'
import {multipleExist} from '../../helpers/multipleExist'
import {deleteHost} from '../../store/hostsReducer'
import {Button, Modal} from 'react-bootstrap'
import {Loading} from '../../components/Loading/Loading'
import {MyBreadcrumb} from '../../components/MyBreadcrumb/MyBreadcrumb'

export const Group = () => {
    const id = useParams<{ id: string }>().id
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const group = useSelector((state: StateType) => state.groupsReducer.group)
    const filter = useSelector((state: StateType) => state.groupsReducer.groupFilter)
    const controller = useMemo(() => new AbortController(), [])
    const [checked, setChecked] = useState<Array<string>>([])
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const {CustomPagination} = usePagination(filter, group?.hosts?.length, (value) => {
        dispatch(setGroupFilter(value))
        history.push({search: filterToString(value)})
        dispatch(getGroup({id, controller}))
        setChecked([])
    })
    const timer = useRef<any>()

    useEffect(() => {
        if (group?.hosts) {
            const filterString = filterFromString(location.search)
            if (filterString && !deepEqual(filterString, filter)) {
                dispatch(setGroupFilter({...filterString}))
            } else if (location.search !== filterToString(filter)) {
                history.replace({search: filterToString(filter)})
            }
        }
    }, [dispatch, filter, history, location, group?.hosts])

    useEffect(() => {
        if (id) {
            dispatch(getGroup({id, controller}))
            timer.current = setInterval(() => dispatch(getGroup({id, controller, noLoading: true})), 30000)
        }
    }, [dispatch, id, controller])

    useEffect(() => {
        return () => {
            dispatch(setGroup(null))
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, controller])

    const allIds = useMemo(() => group?.hosts?.slice(filter.offset, filter.limit.value + filter.offset).map(i => i.id) || [], [group, filter])

    const checkAll = useCallback(() => {
        if (group && group?.hosts) {
            let ids = [...checked]
            const limitHosts = group.hosts.slice(filter.offset, filter.limit.value + filter.offset).map(i => i.id)
            if (multipleExist(ids, limitHosts)) {
                limitHosts.forEach(i => ids = ids.filter(h => h !== i))
            } else {
                limitHosts.forEach(i => {
                    if (!ids.includes(i)) ids = [...ids, i]
                })
            }
            setChecked(ids)
        }
    }, [group, filter, checked])

    const handleCheck = useCallback((value: string) => {
        if (checked.includes(value)) setChecked(checked.filter(i => i !== value))
        else setChecked([...checked, value])
    }, [checked])

    const groupsMap = useMemo(() => group?.hosts && [...group.hosts].slice(filter.offset, filter.limit.value + filter.offset).map(i => {
        let priority = 0

        i.items?.forEach(item => {
            if (item.value === 1 && item.priority > priority) {
                priority = item.priority
            }
        })

        return (
            <tr key={i.id} style={{backgroundColor: priorityColorDefinitionBackground(priority)}}>
                <td className="align-middle"><input className="table-checkbox"
                                                    onChange={() => handleCheck(i.id)}
                                                    checked={checked.includes(i.id)}
                                                    value={i.id}
                                                    type="checkbox"/>
                </td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/hosts/${i.id}`}>{i.name}</NavLink></td>
            </tr>
        )
    }), [group?.hosts, filter, checked, handleCheck])

    const deleteHandler = async () => {
        try {
            setLoading(true)
            const {payload}: any = await dispatch(deleteHost({id: checked.join(';'), controller}))
            if (payload) {
                dispatch(getGroup({id, controller}))
                setShowModal(false)
            }
        } finally {
            setLoading(false)
        }
    }

    if (!group) return <Loading/>

    return (
        <div className="container">
            <MyBreadcrumb />
            <div className="backButton" onClick={() => history.goBack()}><BackButton/></div>
            <Helmet>
                <title>{group?.name}</title>
            </Helmet>
            <h2 className="mb-2">{group?.name}</h2>
            <div style={{fontSize: 16}}>Населенный пункт: {group?.cityName}</div>
            <NavLink to={{pathname: `/formGroup/${group?.id}`}}
                     className="btn btn-primary mt-2"
            >
                Редактировать
            </NavLink>
            <hr className="mt-4 mb-3"/>
            <h2 className="mb-2">Объекты</h2>
            <NavLink to={{pathname: '/formHost', state: {groupId: group?.id}}}
                     className="btn btn-primary mt-2"
            >
                Добавить объект
            </NavLink>
            <div className="table-responsive " style={{flex: 1}}>
                <div className="selectBlock">
                    <Select className="searchSelect"
                            value={filter.limit}
                            onChange={(item) => history.replace({search: filterToString({...filter, limit: item, offset: 0})})}
                            options={limitOptionsSelect}
                            isSearchable={false}
                    />
                    <CustomPagination/>
                </div>
                <table className="table table-sm">
                    <thead>
                    <tr>
                        <th className="align-middle" scope="col"><input className="table-checkbox"
                                                                        checked={checked.length > 0 && multipleExist(checked, allIds)}
                                                                        onChange={checkAll}
                                                                        type="checkbox"/>
                        </th>
                        <th className="align-middle" scope="col">Название</th>
                    </tr>
                    </thead>
                    <tbody>
                    {groupsMap}
                    </tbody>
                </table>
            </div>
            <div style={{display: 'flex'}}>
                <input type="submit"
                       className="btn btn-danger mb-1"
                       style={{marginRight: 10}}
                       value="Удалить"
                       onClick={() => setShowModal(true)}
                       disabled={loading || checked.length === 0}
                />
                <CustomPagination/>
            </div>
            {
                showModal
                    ? <Modal show
                             onHide={() => setShowModal(false)}
                    >
                        <Modal.Body style={{textAlign: 'center'}}>
                            {`Вы уверены что хотите удалить ${checked.length > 1 ? 'объекты' : 'объект'}?`}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={() => setShowModal(false)}>
                                Нет
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={deleteHandler}
                                    disabled={loading}
                            >
                                Да
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
        </div>
    )
}
