import React, {memo, useEffect, useMemo, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from 'react-redux'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {useHistory, useParams} from 'react-router-dom'
import {BackButton} from '../../components/BackButton/BackButton'
import {StateType} from '../../store/store'
import {MyInput} from '../../components/MyForms/MyInput'
import {Loading} from '../../components/Loading/Loading'
import {createAjaxAuth, deleteAjaxAuth, editAjaxAuth, getAjaxAuthAll} from "../../store/AjaxAuthReducer";

const schema = yup.object().shape({
    login: yup.string().trim().email('Это не email').required('Введите email'),
    password: yup.string().required('Введите пароль')
})

export const FormAjaxSystems = memo(() => {
    const dispatch = useDispatch()
    const {id} = useParams<{ id: string }>()
    const ajaxAuthAll = useSelector((state: StateType) => state.ajaxAuthReducer.ajaxAuthAll)
    const profileId = useSelector((state: StateType) => state.appReducer.profileId)
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const controller = useMemo(() => new AbortController(), [])
    const [showModal, setShowModal] = useState(false)
    const ajaxAuth = ajaxAuthAll?.find(i => i.id === parseInt(id, 10))

    useEffect(() => {
        if (profileId) {
            dispatch(getAjaxAuthAll({profileId, controller}))
        }

        return () => {
            controller.abort()
        }
    }, [dispatch, controller, profileId])

    const formik = useFormik({
        initialValues: {
            id: ajaxAuth?.id || null,
            login: ajaxAuth?.login || '',
            password: '',
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            if (values.id && profileId) {
                try {
                    setLoading(true)
                    const {payload}: any = await dispatch(editAjaxAuth({
                        id: values.id,
                        profileId,
                        login: values.login,
                        password: values.password,
                        controller
                    }))
                    if (payload === true) {
                        history.goBack()
                    }
                } finally {
                    setLoading(false)
                }
            } else {
                try {
                    if (profileId) {
                        setLoading(true)
                        const {payload}: any = await dispatch(createAjaxAuth({
                            profileId,
                            login: values.login,
                            password: values.password,
                            controller
                        }))

                        if (payload === true) {
                            history.goBack()
                        }
                    }
                } finally {
                    setLoading(false)
                }
            }
        }
    })
    const {values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, setFieldValue} = formik

    const deleteHandler = async () => {
        try {
           if (values.id) {
               setLoading(true)
               const {payload}: any = await dispatch(deleteAjaxAuth({id: values.id, controller}))
               if (payload) {
                   history.goBack()
               }
           }
        } finally {
            setLoading(false)
        }
    }

    if (id && !ajaxAuth) return <Loading/>

    return (
        <div className="text-center" style={{flex: 1, display: 'flex'}}>
            <Helmet>
                <title>{ajaxAuth ? 'Изменение аккаунта' : 'Добавление аккаунта'}</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
            >
                <div className="backButtonForm" onClick={() => history.goBack()}><BackButton/></div>
                <h1 className="mt-6 mb-4">{id ? 'Изменение аккаунта' : 'Добавление аккаунта'}</h1>
                <div className="formInputs">
                    <MyInput value={values.login}
                             label="Электронная почта"
                             name="login"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => {
                                 setFieldValue('login', '')
                             }}
                             isInvalid={touched.login && errors.login}
                    />
                    <MyInput value={values.password}
                             autoComplete='new-password'
                             label="Пароль"
                             name="password"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => {
                                 setFieldValue('password', '')
                             }}
                             isInvalid={touched.password && errors.password}
                             isPassword
                    />
                </div>
                <div className="form-buttons-block">
                    <Button variant="primary"
                            type="submit"
                            disabled={loading || isSubmitting}
                    >
                        Сохранить
                    </Button>
                    <Button variant="outline-secondary"
                            onClick={() => history.goBack()}
                    >
                        Отмена
                    </Button>
                    {
                        id
                            ? <Button variant="outline-danger"
                                      onClick={() => setShowModal(true)}
                                      disabled={loading || isSubmitting}
                            >
                                Удалить
                            </Button>
                            : null
                    }
                </div>
            </Form>
            {
                showModal
                    ? <Modal show
                             onHide={() => setShowModal(false)}
                    >
                        <Modal.Body style={{textAlign: 'center'}}>
                            <div>Вы уверены что хотите удалить аккаунт?</div>
                            <div>При удалении аккаунта удалится все связанное оборудование.</div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={() => setShowModal(false)}>
                                Нет
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={deleteHandler}
                                    disabled={loading}
                            >
                                Да
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
        </div>
    )
})
