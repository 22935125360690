import {grayColor, orangeColor, redColor} from './colorPicker'

export const priorityColorDefinitionBackground = (priority: number): string => {
    switch (priority) {
        case 1:
            return grayColor
        case 3:
            return orangeColor
        case 4:
            return redColor
        default:
            return '#ffffff'
    }
}

export const priorityColorDefinitionText = (priority: number): string => {
    switch (priority) {
        case 1:
            return grayColor
        case 3:
            return orangeColor
        case 4:
            return '#ff0000'
        default:
            return '#000'
    }
}
