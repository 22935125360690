import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {StateType} from "../../store/store";
import {getAjaxAuthAll} from "../../store/AjaxAuthReducer";
import {NavLink, useHistory} from "react-router-dom";
import {Loading} from "../../components/Loading/Loading";

export const AjaxSystems = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const ajaxAuthAll = useSelector((state: StateType) => state.ajaxAuthReducer.ajaxAuthAll)
    const profileId = useSelector((state: StateType) => state.appReducer.profileId)
    const controller = useMemo(() => new AbortController(), [])

    useEffect(() => {
        if (profileId) {
            dispatch(getAjaxAuthAll({profileId, controller}))
        }

        return () => {
            controller.abort()
        }
    }, [dispatch, controller, profileId])

    const itemsMap = useMemo(() => ajaxAuthAll && [...ajaxAuthAll].map(i => {
        return (
            <tr key={i.id} style={{cursor: 'pointer'}} onClick={() => history.push(`/formAjaxSystems/${i.id}`)}>
                <td className="align-middle">{i.login}</td>
            </tr>
        )
    }), [ajaxAuthAll, history])

    if (!ajaxAuthAll) return <div style={{padding: 15, marginLeft: 30, width: 400}}><Loading/></div>

    return (
        <div style={{padding: 15, marginLeft: 30}}>
            <h1 className="mt-6 mb-4">Аккаунты AjaxSystems</h1>
            <div className="table-responsive " style={{flex: 1}}>
                <table className="table table-sm table-hover">
                    <thead>
                    <tr>
                        <th className="align-middle" scope="col">Email</th>
                    </tr>
                    </thead>
                    <tbody>
                    {itemsMap}
                    </tbody>
                </table>
            </div>
            <NavLink to={{pathname: '/formAjaxSystems'}}
                     className="btn btn-primary mt-2"
            >
                Добавить аккаунт
            </NavLink>
        </div>
    )
}