import React, {memo, useEffect, useState} from 'react'
import s from './dropdownProfile.module.scss'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import {useDispatch} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {Button, Modal} from 'react-bootstrap'
import {logout} from '../../store/appReducer'

export const DropdownProfile = memo(() => {
    const [showModal, setShowModal] = useState(false)
    const [active, setActive] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const handleLogout = async () => {
        dispatch(logout())
        history.push('/')
    }

    useEffect(() => {
        if (location?.pathname?.match(/profile|ajaxSystems|bills|instruction|agreement|tariffs/i)) {
            setActive(true)
        } else setActive(false)
    }, [location])

    return (
        <>
            <DropdownButton className={`${s.customDropdown} ${active && s.active}`}
                            variant="link"
                            title="Личный кабинет">
                <Dropdown.Item active={location?.pathname.includes('profile')} onClick={() => history.push(`/profile`)}>
                    <div className={s.itemWrapper}>
                        {/*<Person/>*/}
                        <div>Профиль</div>
                    </div>
                </Dropdown.Item>
                {/*<Dropdown.Item onClick={() => history.push('/bills')}>*/}
                {/*    <div className={s.itemWrapper}>*/}
                {/*        /!*<BarChart/>*!/*/}
                {/*        <div>Мои счета</div>*/}
                {/*    </div>*/}
                {/*</Dropdown.Item>*/}
                <Dropdown.Divider/>
                <Dropdown.Item active={location?.pathname.includes('instruction')} onClick={() => history.push('/instruction')}>
                    <div className={s.itemWrapper}>
                        {/*<Gear/>*/}
                        <div>Инструкция</div>
                    </div>
                </Dropdown.Item>
                <Dropdown.Item active={location?.pathname.includes('agreement')} onClick={() => history.push('/agreement')}>
                    <div className={s.itemWrapper}>
                        {/*<QuestionCircle/>*/}
                        <div>Соглашение</div>
                    </div>
                </Dropdown.Item>
                {/*<Dropdown.Item onClick={() => history.push('/tariffs')}>*/}
                {/*    <div className={s.itemWrapper}>*/}
                {/*        /!*<QuestionCircle/>*!/*/}
                {/*        <div>Тарифы</div>*/}
                {/*    </div>*/}
                {/*</Dropdown.Item>*/}
                <Dropdown.Divider/>
                <Dropdown.Item onClick={() => setShowModal(true)}>
                    <div className={s.itemWrapper}>
                        {/*<QuestionCircle/>*/}
                        <div>Выйти</div>
                    </div>
                </Dropdown.Item>
            </DropdownButton>
            {
                showModal
                    ? <Modal show
                             onHide={() => setShowModal(false)}
                    >
                        <div className={s.container}>
                            <Modal.Body style={{textAlign: 'center'}}>
                                Вы уверены что хотите выйти?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary"
                                        onClick={() => setShowModal(false)}>
                                    Нет
                                </Button>
                                <Button variant="primary"
                                        type="submit"
                                        onClick={handleLogout}>
                                    Да
                                </Button>
                            </Modal.Footer>
                        </div>
                    </Modal>
                    : null
            }
        </>
    )
})
