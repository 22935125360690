import React, {memo, useEffect, useMemo, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from 'react-redux'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {DeviceType} from '../../Types/Types'
import {createDevice, deleteDevice, editAjaxDevice, editAjaxHub, editDevice, getDevice} from '../../store/deviceReducer'
import {BackButton} from '../../components/BackButton/BackButton'
import {StateType} from '../../store/store'
import {MyInput} from '../../components/MyForms/MyInput'
import {Loading} from '../../components/Loading/Loading'
import {MySelect} from '../../components/MyForms/MySelect'
import {getHosts} from "../../store/hostsReducer";

export const FormDevice = memo(() => {
    const dispatch = useDispatch()
    const location = useLocation<{ params: DeviceType, hostId: string }>()
    const {id} = useParams<{ id: string }>()
    const params = location?.state?.params
    const hostId = location?.state?.hostId
    const history = useHistory()
    const hosts = useSelector((state: StateType) => state.hostsReducer.hosts)
    const device = useSelector((state: StateType) => state.deviceReducer.device)
    const [loading, setLoading] = useState(false)
    const controller = useMemo(() => new AbortController(), [])
    const [showModal, setShowModal] = useState(false)

    const schema = useMemo(() => device?.idAjaxDevice ? yup.object().shape({
        name: yup.string().required('Введите название').max(32, 'Максимальная длина 32 символа'),
    }) : yup.object().shape({
        name: yup.string().required('Введите название').max(32, 'Максимальная длина 32 символа'),
        port: yup.string().required('Введите порт').matches(
            /^([0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
            'Введите корректный порт'
        ),
    }), [device])

    useEffect(() => {
        if (id) dispatch(getDevice({id, controller}))
    }, [dispatch, id, controller])

    useEffect(() => {
        if (hostId && !hosts) dispatch(getHosts({controller}))
    }, [dispatch, hostId, hosts, controller])

    useEffect(() => {
        return () => {
            controller.abort()
        }
    }, [dispatch, controller])

    const formik = useFormik({
        initialValues: {
            name: device?.name || params?.name || '',
            port: device?.port || params?.port || '',
            priority: {value: '', label: ''},
            status: (device && device?.status === '1') || (params && params?.status === '1') || true,
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            await onSubmit(values)
        }
    })
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        validateForm,
        isValid,
        setTouched
    } = formik

    const onSubmit = async (values: any, isContinue = false) => {
        await setTouched(values)
        await validateForm(values)

        if (isValid) {
            const params = {
                id: id,
                hostId: device?.hostid || hostId,
                name: values.name,
                port: values.port,
                priority: values.priority.value,
                status: values.status ? '1' : '0',
            }
            if (id) {
                try {
                    setLoading(true)
                    let res: any
                    if (device?.idAjaxDevice && device?.idAjaxHub && !device?.idAjaxAuth) {
                        const {payload}: any = await dispatch(editAjaxDevice({...params, controller}))
                        res = payload
                    } else if (device?.idAjaxDevice && device?.idAjaxHub && device?.idAjaxAuth) {
                        const {payload}: any = await dispatch(editAjaxHub({...params, controller}))
                        res = payload
                    } else {
                        const {payload}: any = await dispatch(editDevice({...params, controller}))
                        res = payload
                    }
                    if (res === true) {
                        if (isContinue) history.replace('/formDevice', {params})
                        else history.goBack()
                    }
                } finally {
                    setLoading(false)
                }
            } else {
                try {
                    setLoading(true)
                    const {payload}: any = await dispatch(createDevice({...params, controller}))
                    if (payload === true && !isContinue) {
                        history.goBack()
                    }
                } finally {
                    setLoading(false)
                }
            }
        }
    }

    const priorityOptions = useMemo(() => [
        {label: 'Низкий', value: 1},
        {label: 'Средний', value: 3},
        {label: 'Высокий', value: 4}
    ], [])

    useEffect(() => {
        if (!values.priority.value) {
            if (device) {
                const opt = priorityOptions.find(i => i.value === device?.priority)
                setFieldValue('priority', opt)
            } else if (params) {
                const opt = priorityOptions.find(i => i.value === params?.priority)
                setFieldValue('priority', opt)
            } else setFieldValue('priority', priorityOptions[1])
        }
    }, [values, params, priorityOptions, setFieldValue, device])

    const deleteHandler = async () => {
        try {
            setLoading(true)
            const {payload}: any = await dispatch(deleteDevice({id, controller}))
            if (payload) {
                history.goBack()
                history.goBack()
            }
        } finally {
            setLoading(false)
        }
    }

    if (id && !device) return <Loading/>

    return (
        <div className="text-center" style={{flex: 1, display: 'flex'}}>
            <Helmet>
                <title>{id ? 'Изменение оборудования' : 'Добавление оборудования'}</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
            >
                <div className="backButtonForm" onClick={() => history.goBack()}><BackButton/></div>
                <h1 className="mt-6 mb-4">{id ? 'Изменение оборудования' : 'Добавление оборудования'}</h1>
                <div className="formInputs">
                    <MyInput value={values.name}
                             label="Название"
                             name="name"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => {
                                 setFieldValue('name', '')
                             }}
                             isInvalid={touched.name && errors.name}
                    />
                    {
                        device?.idAjaxDevice
                            ? <MyInput value={device.idAjaxDevice}
                                       label="ID Ajax Systems"
                                       name="idAjaxDevice"
                                       disabled
                            />
                            : <MyInput value={values.port}
                                       label="Порт"
                                       name="port"
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       clear={() => {
                                           setFieldValue('port', '')
                                       }}
                                       isInvalid={touched.port && errors.port}
                            />
                    }
                    <MySelect value={values.priority}
                              label="Приоритет"
                              options={priorityOptions}
                              onChange={(item) => setFieldValue('priority', item)}
                              error={touched.priority && !!errors.priority && errors.priority.value}
                    />
                    <div className="checkbox mb-3 mt-3">
                        <label>
                            <Form.Check type="checkbox"
                                        id="status"
                                        checked={values.status}
                                        onChange={handleChange}
                                        label="Активировано"
                            />
                        </label>
                    </div>
                </div>
                <div className="form-buttons-block">
                    <Button variant="primary"
                            type="submit"
                            disabled={loading || isSubmitting}
                    >
                        Сохранить
                    </Button>
                    {
                        device?.idAjaxDevice ? null : <Button variant="primary"
                                                                disabled={loading || isSubmitting}
                                                                onClick={() => onSubmit(values, true)}
                        >
                            Продолжить
                        </Button>
                    }
                    <Button variant="outline-secondary"
                            onClick={() => history.goBack()}
                    >
                        Отмена
                    </Button>
                    {
                        device
                            ? <Button variant="outline-danger"
                                      onClick={() => setShowModal(true)}
                                      disabled={loading || isSubmitting}
                            >
                                Удалить
                            </Button>
                            : null
                    }
                </div>
            </Form>
            {
                showModal
                    ? <Modal show
                             onHide={() => setShowModal(false)}
                    >
                        <Modal.Body style={{textAlign: 'center'}}>
                            <div>Вы уверены что хотите удалить оборудование?</div>
                            {device?.idAjaxAuth ? <div>При удалении Ajax Hub удалится все связанное оборудование</div> : null}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={() => setShowModal(false)}>
                                Нет
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={deleteHandler}
                                    disabled={loading || isSubmitting}
                            >
                                Да
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
        </div>
    )
})
