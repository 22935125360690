import React, {useEffect, useMemo, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {StateType} from '../../store/store'
import {priorityColorDefinitionBackground} from '../../helpers/priorityColorDefinition'
import {usePagination} from '../../hooks/usePagination'
import Select from 'react-select'
import {limitOptionsSelect} from '../../helpers/tableHelper'
import {NavLink, useHistory, useLocation, useParams} from 'react-router-dom'
import {filterToString} from '../../helpers/filterToString'
import {filterFromString} from '../../helpers/filterFromString'
import deepEqual from 'deep-equal'
import {Helmet} from 'react-helmet-async'
import {getHost, setHostDevicesFilter} from '../../store/hostsReducer'
import {priorityDecoder} from '../../helpers/priorityDecoder'
import {getDuration} from '../../helpers/getDuration'
import Moment from 'moment'
import {BackButton} from '../../components/BackButton/BackButton'
import {Loading} from '../../components/Loading/Loading'
import {getProblem, setProblem} from '../../store/problemsReducer'
import {MyBreadcrumb} from '../../components/MyBreadcrumb/MyBreadcrumb'

export const Problem = () => {
    const id = useParams<{ id: string }>().id
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const problem = useSelector((state: StateType) => state.problemsReducer.problem)
    const filter = useSelector((state: StateType) => state.hostsReducer.devicesFilter)
    const {CustomPagination} = usePagination(filter, problem?.items?.length, (value) => {
        dispatch(setHostDevicesFilter(value))
        history.push({search: filterToString(value)})
        dispatch(getHost({id, controller}))
    })
    const controller = useMemo(() => new AbortController(), [])
    const timer = useRef<any>()

    useEffect(() => {
        if (problem?.items) {
            const filterString = filterFromString(location.search)
            if (filterString && !deepEqual(filterString, filter)) {
                dispatch(setHostDevicesFilter({...filterString}))
            } else if (location.search !== filterToString(filter)) {
                history.replace({search: filterToString(filter)})
            }
        }
    }, [dispatch, filter, history, location, problem?.items])

    useEffect(() => {
        (async () => {
            if (id) {
                const {payload}: any = await dispatch(getProblem({id, controller}))
                if (payload === false) history.push('/problems')
                timer.current = setInterval(async () => {
                    const {payload}: any = await dispatch(getProblem({id, controller, noLoading: true}))
                    if (payload === false) history.push('/problems')
                }, 30000)
            }
        })()
    }, [dispatch, id, controller, history])

    useEffect(() => {
        return () => {
            dispatch(setProblem(null))
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, controller])

    const itemsMap = useMemo(() => problem?.items && [...problem.items].slice(filter.offset, filter.limit.value + filter.offset).map(i => {
        return (
            <tr key={i.id} style={{cursor: 'pointer', backgroundColor: priorityColorDefinitionBackground(i.priority)}}
                onClick={() => history.push(`/devices/${i.id}`)}>
                <td className="align-middle"><NavLink className="custom-link-table-item" onClick={(e) => e.stopPropagation()} to={`/devices/${i.id}`}>{i.name}</NavLink></td>
                <td className="align-middle">{priorityDecoder(i.priority)}</td>
                <td className="align-middle">{i.port}</td>
                <td className="align-middle">{i.status === '1' ? 'Активировано' : 'Деактивировано'}</td>
                <td className="align-middle">{i.value === 1 ? 'ПРОБЛЕМА' : 'ОК'}</td>
                <td className="align-middle">{getDuration(i.duration)}</td>
                <td className="align-middle">{i.lastchange ? Moment.utc(i.lastchange * 1000).format('DD.MM.YYYY HH:mm:ss') : ''}</td>
                {/*<td className="align-middle">-</td>*/}
            </tr>
        )
    }), [problem?.items, filter, history])

    if (!problem) return <Loading/>

    return (
        <div className="container">
            <MyBreadcrumb/>
            <div className="backButton" onClick={() => history.goBack()}><BackButton/></div>
            <Helmet>
                <title>{problem?.name}</title>
            </Helmet>
            <h2 className="mt-1 mb-1"><NavLink to={`/hosts/${problem?.id}`} className="mt-1 mb-1 custom-link-table-item">{problem?.name}</NavLink></h2>
            <div style={{fontSize: 16}}>ID: {problem?.id}</div>
            <div style={{fontSize: 16}}>Протокол: {problem?.https ? 'HTTPS' : 'HTTP'}</div>
            <div style={{fontSize: 16}}>IP-адрес: {problem?.ip}</div>
            <div style={{fontSize: 16}}>Порт управления: {problem?.controlPort}</div>
            <div style={{fontSize: 16}}>Физический адрес: {problem?.address}</div>
            <div style={{fontSize: 16}}>Местоположение: {problem?.lat && problem?.lon ? 'Установлено' : 'Не установлено'}</div>
            {
                problem?.ip && problem?.controlPort
                    ? <div><a target="_blank" rel="noreferrer" href={`${problem?.https ? 'HTTPS' : 'HTTP'}://${problem?.ip}:${problem?.controlPort}`}>Управление</a></div>
                    : null
            }
            <NavLink to={{pathname: `/formHost/${problem?.id}`}}
                     className="btn btn-primary mt-2"
            >
                Редактировать
            </NavLink>
            <hr className="mt-4 mb-3"/>
            <h2 className="mb-2">Оборудование</h2>
            <div className="table-responsive " style={{flex: 1}}>
                <div className="selectBlock">
                    <Select className="searchSelect"
                            value={filter.limit}
                            onChange={(item) => history.replace({search: filterToString({...filter, limit: item, offset: 0})})}
                            options={limitOptionsSelect}
                            isSearchable={false}
                    />
                    <CustomPagination/>
                </div>
                <table className="table table-sm table-hover">
                    <thead>
                    <tr>
                        <th className="align-middle" scope="col">Название</th>
                        <th className="align-middle" scope="col">Важность</th>
                        <th className="align-middle" scope="col">Порт</th>
                        <th className="align-middle" scope="col">Статус</th>
                        <th className="align-middle" scope="col">Состояние</th>
                        <th className="align-middle" scope="col">Длительность</th>
                        <th className="align-middle" scope="col">Последнее отключение</th>
                        {/*<th className="align-middle" scope="col">Подтверждено</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {itemsMap}
                    </tbody>
                </table>
            </div>
            <CustomPagination/>
        </div>
    )
}
