import React from 'react'
import {NavLink} from 'react-router-dom'
import {ProfileType} from '../../Types/Types'
import {DropdownProfile} from '../DropdownProfile/DropdownProfile'

type PropsType = {
    token: string | null
    profileType: ProfileType | null
}
export const Navigation: React.FC<PropsType> = ({profileType, token}) => {

    return (
        <header className="py-3 mb-4 border-bottom bg-light navbar-light shadow">
            <div className="container d-flex flex-nowrap">
                <NavLink to="/" className="d-flex align-items-center mb-md-0 text-dark text-decoration-none" style={{marginRight: 20, flex: 1}}>
                    <span style={{fontSize: '1.5rem'}}>Система мониторинга</span>
                </NavLink>
                {
                    !token
                        ? <ul className="nav nav-pills align-items-center flex-nowrap">
                            <li className="nav-item"><NavLink to="/" exact className="nav-link">Главная</NavLink></li>
                            <li className="nav-item"><NavLink to="/instruction" className="nav-link">Инструкция</NavLink></li>
                            <li className="nav-item"><NavLink to="/agreement" className="nav-link ">Соглашение</NavLink></li>
                            {/*<li className="nav-item"><NavLink to="/tariffs" className="nav-link">Тарифы</NavLink></li>*/}
                            <li className="nav-item"><NavLink to="/registration" className="nav-link">Регистрация</NavLink></li>
                            <li className="nav-item"><NavLink to="/login" className="nav-link">Вход</NavLink></li>
                        </ul>
                        : <ul className="nav nav-pills align-items-center  flex-nowrap">
                            <li className="nav-item"><NavLink to="/" exact className="nav-link">Главная</NavLink></li>
                            <li className="nav-item"><NavLink to="/problems" className="nav-link">Проблемы</NavLink></li>
                            <li className="nav-item">
                                <NavLink to="/groups"
                                         className="nav-link"
                                         isActive={(match, location) => {
                                             if (location.pathname.match(/\/formGroup/)) return true
                                             return !!match
                                         }}
                                >Группы</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/hosts"
                                         className="nav-link"
                                         isActive={(match, location) => {
                                             if (location.pathname.match(/\/devices/)) return true
                                             if (location.pathname.match(/\/formHost/)) return true
                                             if (location.pathname.match(/\/formDevice/)) return true
                                             if (location.pathname.match(/\/setCoordinates/)) return true
                                             return !!match
                                         }}
                                >Объекты</NavLink>
                            </li>
                            <li className="nav-item"><NavLink to="/map" className="nav-link">Карта</NavLink></li>
                            <li className="nav-item"><NavLink to="/analytics" className="nav-link">Аналитика</NavLink></li>
                            {/*<li className="nav-item"><NavLink to="/reports" className="nav-link">Отчеты</NavLink></li>*/}
                            <li className="nav-item"><DropdownProfile/></li>
                        </ul>
                }

            </div>
        </header>
    )
}
