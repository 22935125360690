import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {editProfile, getProfile, setMessage} from '../../store/appReducer'
import {StateType} from '../../store/store'
import {NavLink, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import {timezones} from '../../helpers/timezones'
import {Helmet} from 'react-helmet-async'
import {Button, Form, Spinner} from 'react-bootstrap'
import * as yup from 'yup'
import {profileTypeDefinition} from '../../helpers/profileTypeDefinition'
import {BackButton} from '../../components/BackButton/BackButton'
import {MyInput} from '../../components/MyForms/MyInput'
import {Loading} from '../../components/Loading/Loading'
import {MySelect} from '../../components/MyForms/MySelect'
import {AjaxSystems} from "../AjaxSystems/AjaxSystems";

const schema = yup.object().shape({
    telegram_id: yup.number().typeError('Введите число'),
    timezone: yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required('Выберите часовую зону'),
    }),
})

export const Profile = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const controller = useMemo(() => new AbortController(), [])
    const profile = useSelector((state: StateType) => state.appReducer.profile)
    const status = useSelector((state: StateType) => state.appReducer.status)
    const [defaultMapOnlyProblem, setDefaultMapOnlyProblem] = useState(false)

    useEffect(() => {
        dispatch(getProfile({controller}))
    }, [dispatch, controller])

    useEffect(() => {
        (async () => {
            const res = await localStorage.getItem('@mapOnlyProblem')
            setDefaultMapOnlyProblem(res === 'true')
        })()
    }, [])

    const formik = useFormik({
        initialValues: {
            name: profile?.name || '',
            telephone: profile?.telephone || '',
            telegram_id: profile?.telegram_id || '',
            email_notification: profile?.email_notification || false,
            telegram_notification: profile?.telegram_notification || false,
            timezone: {value: '', label: ''},
            ajax_systems: profile?.ajax_systems || false,
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const {payload}: any = await dispatch(editProfile({
                name: values.name,
                telephone: values.telephone,
                telegram_id: values.telegram_id,
                timezone: values.timezone.value,
                email_notification: values.email_notification,
                telegram_notification: values.telegram_notification,
                ajax_systems: values.ajax_systems,
                controller
            }))
            if (payload) {
                dispatch(getProfile({controller}))
                dispatch(setMessage({type: 'success', message: 'Профиль обновлен'}))
            }
        }
    })
    const {values, handleChange, handleSubmit, touched, errors, isSubmitting, setFieldValue} = formik

    const timezoneOptions = timezones.map(({value, label}) => ({label, value}))

    useEffect(() => {
        if (!values.timezone.value) {
            if (profile?.timezone) {
                const opt = timezoneOptions.find(i => i.value === parseInt(profile?.timezone, 10))
                setFieldValue('timezone', opt)
            } else setFieldValue('timezone', timezoneOptions[15])
        }
    }, [values, setFieldValue, profile, timezoneOptions])

    useEffect(() => {
        if (!values.telegram_id) setFieldValue('telegram_notification', false)
    }, [values, setFieldValue])

    if (!profile) return <Loading/>

    return (
        <div style={{flex: 1}}>
            <div className="backButtonForm" onClick={() => history.goBack()}><BackButton/></div>
            <Helmet>
                <title>Личные данные</title>
            </Helmet>
            <div className='text-center' style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                <Form onSubmit={handleSubmit}
                      autoComplete="off"
                      className="formSignin"
                      style={{maxWidth: 330, margin: 0}}
                >
                    <h1 className="mt-6 mb-4">Личные данные</h1>
                    <MyInput value={profile?.id || ''}
                             label="Идентификатор"
                             name="name"
                             disabled
                    />
                    <MyInput value={profile?.email || ''}
                             label="Электронная почта"
                             name="email"
                             disabled
                    />
                    <MyInput value={profileTypeDefinition(profile?.type)}
                             label="Тип"
                             name="type"
                             disabled
                    />
                    <MyInput value={values.name}
                             label="Организация"
                             name="name"
                             onChange={handleChange}
                             clear={() => {
                                 setFieldValue('name', '')
                             }}
                    />
                    <MyInput value={values.telephone}
                             label="Телефонный номер"
                             name="telephone"
                             onChange={handleChange}
                             clear={() => {
                                 setFieldValue('telephone', '')
                             }}
                    />
                    <MyInput value={values.telegram_id}
                             label={<div>Telegram ID{values.telegram_id && profile?.telegram_id && values.telegram_id === profile?.telegram_id
                                 && <span style={{color: profile?.telegram_confirmed ? 'green' : '#ffc107'}}>
                                 {profile?.telegram_confirmed ? ' (подтвержден)' : ' (не подтвержден)'}
                             </span>}</div>}
                             name="telegram_id"
                             onChange={handleChange}
                             clear={() => {
                                 setFieldValue('telegram_id', '')
                             }}
                             isInvalid={touched.telegram_id && errors.telegram_id}
                    />
                    <MySelect value={values.timezone}
                              label="Часовая зона"
                              options={timezoneOptions}
                              onChange={(item) => setFieldValue('timezone', item)}
                              error={touched.timezone && !!errors.timezone && errors.timezone.value}
                    />
                    {
                        profile?.telegram_id && !profile?.telegram_confirmed
                            ? <div className="alert alert-warning" role="alert">
                                <h4>У вас не подтверждён telegram ID.</h4>
                                <p>Уведомления не будут поступать по данному каналу до подтверждения.</p>
                                <p>Перед подтверждением напишите боту <a href="https://t.me/dist_syst_bot">@dist_syst_bot</a> (введите команду /start или нажмите на кнопку "Начать").</p>
                                <hr/>
                                <NavLink className="btn btn-secondary" to="/confirmTelegram">Подтвердить</NavLink>
                            </div>
                            : null
                    }
                    <div className="checkbox mb-3 mt-3" style={{display: 'flex'}}>
                        <label>
                            <Form.Check type="checkbox"
                                        id="telegram_notification"
                                        checked={values.telegram_notification}
                                        onChange={handleChange}
                                        disabled={!profile?.telegram_confirmed}
                                        label="Отправлять уведомления в Telegram"
                            />
                        </label>
                    </div>
                    <div className="checkbox mb-3 mt-3" style={{display: 'flex'}}>
                        <label>
                            <Form.Check type="checkbox"
                                        id="email_notification"
                                        checked={values.email_notification}
                                        onChange={handleChange}
                                        label="Отправлять уведомления на email"
                            />
                        </label>
                    </div>
                    <div className="checkbox mb-3 mt-3" style={{display: 'flex'}}>
                        <label>
                            <Form.Check type="checkbox"
                                        id="ajax_systems"
                                        checked={values.ajax_systems}
                                        onChange={handleChange}
                                        label="Использовать Ajax Systems"
                            />
                        </label>
                    </div>
                    <div className="form-buttons-block">
                        <Button variant="primary"
                                type="submit"
                                disabled={status === 'loading' || isSubmitting}
                        >
                            {isSubmitting ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : 'Сохранить изменения'}
                        </Button>
                        <Button variant="outline-secondary"
                                onClick={() => history.goBack()}
                        >
                            Отмена
                        </Button>
                    </div>
                </Form>
                <div style={{marginLeft: 20}}>
                    {profile?.ajax_systems ? <AjaxSystems /> : null}
                    <div className="mb-3 mt-5">
                        <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 5}}>Карта</div>
                        <div className="checkbox">
                            <label>
                                <Form.Check type="checkbox"
                                            id="mapOnlyProblem"
                                            checked={defaultMapOnlyProblem}
                                            onChange={async () => {
                                                await localStorage.setItem('@mapOnlyProblem', defaultMapOnlyProblem ? 'false' : 'true')
                                                setDefaultMapOnlyProblem(!defaultMapOnlyProblem)
                                            }}
                                            label="По умолчанию показывать на карте только проблемы"
                                />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
