import React, {memo} from 'react'

export const BadRequest = memo(() => {
    return (
        <div className="container" style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <h1 style={{fontSize: 50}}>404</h1>
            <p style={{fontSize: 40}}>Страница не найдена.</p>
        </div>
    )
})
