export const timezones = [
    {value: 0, label: 'UTC-11 Паго-Паго'},
    {value: 1, label: 'UTC-10 США (Гавайи)'},
    {value: 2, label: 'UTC-8 США (Аляска)'},
    {value: 3, label: 'UTC-7 США (Калифорния)'},
    {value: 4, label: 'UTC-6 США (Колорадо)'},
    {value: 5, label: 'UTC-5 США (Техас)'},
    {value: 6, label: 'UTC-4 Нью-Йорк'},
    {value: 7, label: 'UTC-3 Бразилия'},
    {value: 8, label: 'UTC-2:30 Канада'},
    {value: 9, label: 'UTC-2 Гренладния'},
    {value: 10, label: 'UTC-1 Кабо-Верде'},
    {value: 11, label: 'UTC+0 Монровия'},
    {value: 12, label: 'UTC+1 Лондон'},
    {value: 13, label: 'UTC+2 Калининград'},
    {value: 14, label: 'UTC+3 Москва'},
    {value: 15, label: 'UTC+4 Самара'},
    {value: 16, label: 'UTC+4:30'},
    {value: 17, label: 'UTC+5 Екатеринбург'},
    {value: 18, label: 'UTC+5:30 Нью-Дели'},
    {value: 19, label: 'UTC+5:45 Катманду'},
    {value: 20, label: 'UTC+6 Омск'},
    {value: 21, label: 'UTC+6:30 Мьянма'},
    {value: 22, label: 'UTC+7 Новосибирск'},
    {value: 23, label: 'UTC+8 Иркутск'},
    {value: 24, label: 'UTC+9 Чита'},
    {value: 25, label: 'UTC+9:30 Дарвин'},
    {value: 26, label: 'UTC+10 Хабаровск'},
    {value: 27, label: 'UTC+11 Южно-Сахалинск'},
    {value: 28, label: 'UTC+12 Петропавловск-Камчатский'},
    {value: 29, label: 'UTC+13'},
]



