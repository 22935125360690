import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {problemAPI} from '../api/API'
import {HostType, SelectOption} from '../Types/Types'
import {limitOptionsSelect} from '../helpers/tableHelper'
import {asyncCreator} from './appReducer'

export type ProblemsFilter = {
    limit: SelectOption<number>
    offset: number
}
type InitialStateType = {
    problems: Array<HostType> | null
    problem: HostType | null
    filter: ProblemsFilter
}

const initialState: InitialStateType = {
    problems: null,
    problem: null,
    filter: {
        limit: limitOptionsSelect[0],
        offset: 0,
    },
}

export const problemsReducer = createSlice({
    name: 'problemsReducer',
    initialState,
    reducers: {
        setProblems(state, {payload}) {
            state.problems = payload
        },
        setProblem(state, {payload}) {
            state.problem = payload
        },
        setProblemsFilter(state, {payload}: PayloadAction<any>) {
            state.filter = payload
        },
    }
})

export const {
    setProblems, setProblem, setProblemsFilter
} = problemsReducer.actions

export default problemsReducer.reducer

export const getProblems = asyncCreator<{ controller: any }>('problemsReducer/getProblems', problemAPI.getProblems, setProblems)
export const getProblem = asyncCreator<{ id: string, controller?: any }>('problemsReducer/getProblem', problemAPI.getProblem, setProblem)
