import React, {useEffect, useMemo, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {StateType} from '../../store/store'
import {priorityColorDefinitionBackground} from '../../helpers/priorityColorDefinition'
import {usePagination} from '../../hooks/usePagination'
import Select from 'react-select'
import {limitOptionsSelect} from '../../helpers/tableHelper'
import {getProblems, setProblems, setProblemsFilter} from '../../store/problemsReducer'
import {NavLink, useHistory, useLocation} from 'react-router-dom'
import {filterToString} from '../../helpers/filterToString'
import {filterFromString} from '../../helpers/filterFromString'
import deepEqual from 'deep-equal'
import {Helmet} from 'react-helmet-async'
import {WidgetProblem} from './WidgetProblem'
import {Loading} from '../../components/Loading/Loading'

export const Default = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const problems = useSelector((state: StateType) => state.problemsReducer.problems)
    const token = useSelector((state: StateType) => state.appReducer.token)
    const statistics = useSelector((state: StateType) => state.appReducer.statistics)
    const filter = useSelector((state: StateType) => state.problemsReducer.filter)
    const {CustomPagination} = usePagination(filter, problems?.length, (value) => {
        dispatch(setProblemsFilter(value))
        history.push({search: filterToString(value)})
    })
    const controller = useMemo(() => new AbortController(), [])
    const timer = useRef<any>()

    useEffect(() => {
        if (token && problems) {
            const filterString = filterFromString(location.search)
            if (filterString && !deepEqual(filterString, filter)) {
                dispatch(setProblemsFilter({...filterString}))
            } else if (location.search !== filterToString(filter)) {
                history.replace({search: filterToString(filter)})
            }
        }
    }, [dispatch, filter, history, location, problems, token])

    useEffect(() => {
        if (token) {
            dispatch(getProblems({controller}))
            timer.current = setInterval(() => dispatch(getProblems({controller, noLoading: true})), 30000)
        } else dispatch(setProblems(null))

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, controller, token])

    const problemsMap = useMemo(() => problems && [...problems].slice(filter.offset, filter.limit.value + filter.offset).map(i => {
        let priority = 0

        i.items?.forEach(item => {
            if (item.value === 1 && item.priority > priority) {
                priority = item.priority
            }
        })

        return (
            <tr key={i.id} style={{backgroundColor: priorityColorDefinitionBackground(priority)}}>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/problems/${i.id}`}>{i.id}</NavLink></td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/problems/${i.id}`}>{i.name}</NavLink></td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/groups/${i.groupId}`}>{i.groupName}</NavLink></td>
                <td className="align-middle">{i.address}</td>
                <td className="align-middle">{i.items?.map((item, index) => {
                    const lastItem = i.items.length === index + 1
                    return <span key={item.id}><NavLink className="custom-link-table-item" to={`/devices/${item.id}`}>{item.name}</NavLink>{!lastItem ? ', ' : ''}</span>
                })}
                </td>
            </tr>
        )
    }), [problems, filter])

    if (!token) return (
        <div className="container flex-d justify-content-center text-center" style={{marginTop: '12rem'}}>
            <Helmet>
                <title>Главная</title>
            </Helmet>
            <div>
                <h1 className="display-1">Статистика мониторинга</h1>
                <br/>
                <h2>Объектов: <u>{statistics?.kol_obj}</u></h2>
                <h2>Устройств: <u>{statistics?.kol_obor}</u></h2>
            </div>
        </div>
    )

    return (
        <div className="table-responsive container" style={{flex: 1}}>
            <Helmet>
                <title>Главная</title>
            </Helmet>
            <h2 className="mb-2">Главная</h2>
            <div className="selectBlock">
                <Select className="searchSelect"
                        value={filter.limit}
                        onChange={(item) => history.replace({search: filterToString({...filter, limit: item, offset: 0})})}
                        options={limitOptionsSelect}
                        isSearchable={false}
                />
                <CustomPagination/>
            </div>
            <table className="table table-sm">
                <thead>
                <tr>
                    <th className="align-middle" scope="col">ID</th>
                    <th className="align-middle" scope="col">Объект</th>
                    <th className="align-middle" scope="col">Группа</th>
                    <th className="align-middle" scope="col">Адрес</th>
                    <th className="align-middle" scope="col">Оборудование с проблемами</th>
                </tr>
                </thead>
                <tbody>
                {problems ? problemsMap : <tr>
                    <td colSpan={5}><Loading/></td>
                </tr>}
                </tbody>
            </table>
            <CustomPagination/>
            {problems ? <WidgetProblem/> : null}
        </div>
    )
}
