import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {hostAPI} from '../api/API'
import {CityType, HostType, SelectOption} from '../Types/Types'
import {limitOptionsSelect} from '../helpers/tableHelper'
import {asyncCreator} from './appReducer'
import {SingleValue} from 'react-select'

export type HostsFilter = {
    limit: SelectOption<number>
    offset: number
    idGroup?: SingleValue<SelectOption<string>>
    searchName?: string
}

type InitialStateType = {
    hosts: Array<HostType> | null
    host: HostType | null
    filter: HostsFilter
    devicesFilter: HostsFilter
    cities: Array<CityType> | null
}

const initialState: InitialStateType = {
    hosts: null,
    host: null,
    filter: {
        limit: limitOptionsSelect[0],
        offset: 0
    },
    devicesFilter: {
        limit: limitOptionsSelect[0],
        offset: 0
    },
    cities: null
}

export const hostsReducer = createSlice({
    name: 'objectsReducer',
    initialState,
    reducers: {
        setHosts(state, {payload}) {
            state.hosts = payload
            if (state.hosts) {
                state.hosts.forEach(i => {
                    if (!i.items) i.items = []
                })
            }
        },
        setHost(state, {payload}) {
            state.host = payload
        },
        setHostsFilter(state, {payload}: PayloadAction<any>) {
            state.filter = payload
        },
        setHostDevicesFilter(state, {payload}: PayloadAction<any>) {
            state.devicesFilter = payload
        },
        setCities(state, {payload}) {
            state.cities = payload
        },
    }
})

export const {
    setHosts, setHost, setHostsFilter, setHostDevicesFilter, setCities
} = hostsReducer.actions

export default hostsReducer.reducer

export const getHosts = asyncCreator<{ controller: any }>('objectsReducer/getHosts', hostAPI.getHosts, setHosts)
export const getHost = asyncCreator<{ id: string, controller: any }>('objectsReducer/getHost', hostAPI.getHost, setHost)
export const createHost = asyncCreator<CreateHost>('objectsReducer/createHost', hostAPI.createHost, null, 'Объект создан')
export const editHost = asyncCreator<EditHost>('objectsReducer/editHost', hostAPI.editHost, null, 'Изменения сохранены')
export const deleteHost = asyncCreator<{ id: string }>('objectsReducer/deleteHost', hostAPI.deleteHost, null, 'Объект удален')
export const getCities = asyncCreator<{ controller: any }>('objectsReducer/getObjects', hostAPI.getCities, setCities)

type CreateHost = {
    name: string
    ip: string
    groupId: string
    controlPort: number
    address: string
    lat: string
    lon: string
}

type EditHost = {
    id: string
} & CreateHost
