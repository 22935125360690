import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Map, Placemark, YMaps} from '@pbe/react-yandex-maps'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {StateType} from '../../store/store'
import {getCities, getHosts} from '../../store/hostsReducer'
import Select from 'react-select'
import {getGroups} from '../../store/groupsReducer'
import {setMapOnlyProblem, setMapState, setSelectedCity} from '../../store/appReducer'
import {Helmet} from 'react-helmet-async'
import {DeviceType} from '../../Types/Types'
import {priorityColorDefinitionText} from '../../helpers/priorityColorDefinition'
import {Form} from 'react-bootstrap'

export const MapComponent = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const selectedCity = useSelector((state: StateType) => state.appReducer.selectedCity)
    const mapState = useSelector((state: StateType) => state.appReducer.mapState)
    const mapOnlyProblem = useSelector((state: StateType) => state.appReducer.mapOnlyProblem)
    const hosts = useSelector((state: StateType) => state.hostsReducer.hosts)
    const cities = useSelector((state: StateType) => state.hostsReducer.cities)
    const groups = useSelector((state: StateType) => state.groupsReducer.groups)
    const controller = useMemo(() => new AbortController(), [])
    const [objectCities, setObjectCities] = useState<Array<{ value: number, label: string }>>([])
    const timer = useRef<any>()

    useEffect(() => {
        // @ts-ignore
        window.myClick = (id: number, lat: string, lon: string) => {
            dispatch(setMapState({
                center: [parseFloat(lat), parseFloat(lon)],
                zoom: 12
            }))
            history.push(`/hosts/${id}`)
        }
    }, [dispatch, history])

    useEffect(() => {
        dispatch(getHosts({controller}))
        dispatch(getGroups({controller}))
        timer.current = setInterval(() => dispatch(getHosts({controller, noLoading: true})), 30000)

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, controller])

    useEffect(() => {
        if (!cities) dispatch(getCities({controller}))
    }, [dispatch, controller, cities])

    useEffect(() => {
        if (groups) {
            const cities: Array<{ value: number, label: string }> = []
            groups.forEach(i => {
                if (i.cityId && i.cityName && !cities.find(c => c.value === i.cityId)) {
                    cities.push({value: i.cityId, label: i.cityName})
                }
            })
            setObjectCities(cities)
        }
    }, [groups])

    useEffect(() => {
        if (objectCities.length > 0 && !selectedCity) dispatch(setSelectedCity(objectCities[0]))
    }, [dispatch, objectCities, selectedCity])

    useEffect(() => {
        if (selectedCity && cities && !mapState) {
            const city = cities.find(i => i.id === selectedCity.value)
            if (city) dispatch(setMapState({
                center: [city.lat, city.lon],
                zoom: 12
            }))
        }
    }, [dispatch, selectedCity, cities, mapState])

    const markers = useMemo(() => hosts && [...hosts].filter(i => {
        if (i.lat && i.lon) {
            if (mapOnlyProblem) return i.items?.some(a => a.value !== 0)
            else return true
        }
        return false
    }).map(i => {
        let problemsCount = 0
        let itemsWithProblem: Array<DeviceType> = []
        let priority = i.items.length > 0 ? Math.max(...i.items.map(d => {
            if (d.value !== 0) {
                problemsCount += 1
                itemsWithProblem.push(d)
                return d.priority
            } else return 0
        })) : 0
        let icon

        switch (priority) {
            case 1:
                icon = 'islands#yellowCircleIcon'
                break
            case 3:
                icon = 'islands#orangeCircleIcon'
                break
            case 4:
                icon = 'islands#redCircleIcon'
                break
            default:
                icon = 'islands#greenCircleIcon'
        }

        if (!i.lat || !i.lon) return <></>

        let htmlItems = ''
        if (itemsWithProblem.length > 0) {
            htmlItems = '<hr><div>'
            itemsWithProblem.forEach((item, index) => {
                htmlItems += `<span style="color: ${priorityColorDefinitionText(item.priority)}">${item.name}</span>`
                if (index !== itemsWithProblem.length - 1) htmlItems += ', '
            })
            htmlItems += '</div>'
        }

        return <Placemark key={i.id}
                          modules={['geoObject.addon.balloon']}
                          geometry={[parseFloat(i.lat), parseFloat(i.lon)]}
                          properties={{
                              iconContent: problemsCount || '',
                              balloonContent: `
                                        <div style="cursor: pointer;" onclick="window.myClick(${i.id}, ${i.lat}, ${i.lon})">
                                            <div style="font-weight: bold;">${i.name}</div>
                                            <div>${i.groupName}</div>
                                            <div>${i.address || ''}</div>
                                            ${htmlItems}
                                        </div>
                              `
                          }}
                          options={{preset: icon, hideIconOnBalloonOpen: false}}
        />
    }), [hosts, mapOnlyProblem])

    const defaultState = {
        center: [55.751244, 37.618423],
        zoom: 5,
        controls: ['zoomControl']
    }

    return (
        <div style={{flex: 1, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Helmet>
                <title>Карта</title>
            </Helmet>
            {objectCities.length > 0 ? <div style={{display: 'flex', gap: 20, alignSelf: 'center', position: 'absolute', zIndex: 20, top: 10}}>
                <div style={{width: 400}}>
                    <Select value={selectedCity}
                            onChange={(item) => {
                                dispatch(setSelectedCity(item))
                                const city = cities?.find(i => i.id === item?.value)
                                if (city) dispatch(setMapState({
                                    center: [city.lat, city.lon],
                                    zoom: 12
                                }))
                            }}
                            options={objectCities}
                            isSearchable={false}
                    />
                </div>
                <div className="checkbox" style={{display: 'flex', alignItems: 'center', backgroundColor: '#fff', padding: '0 10px', border: '1px solid #CCCCCC', borderRadius: 4}}>
                    <label>
                        <Form.Check type="checkbox"
                                    id="checkbox"
                                    checked={mapOnlyProblem}
                                    onChange={() => dispatch(setMapOnlyProblem(!mapOnlyProblem))}
                                    label="Только проблемы"
                        />
                    </label>
                </div>
            </div> : null}
            <YMaps>
                <Map defaultState={defaultState}
                     state={mapState}
                     width="100%"
                     height="100%"
                     modules={['control.ZoomControl']}
                >
                    {markers}
                </Map>
            </YMaps>
        </div>
    )
}
