export const getDuration = (duration: number): string => {
    const days = Math.floor(duration / (3600 * 24))
    const hours = Math.floor(duration % (3600 * 24) / 3600)
    const minutes = Math.floor(duration % 3600 / 60)
    const seconds = Math.floor(duration % 60)

    return (days > 0 ? `${days}д ` : '')
        + (hours > 0 ? `${hours}ч ` : '')
        + (minutes > 0 ? `${minutes}м ` : '')
        + (seconds > 0 ? `${seconds}с` : '')
}
