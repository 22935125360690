import {ProfileType} from '../Types/Types'

export const profileTypeDefinition = (value?: ProfileType) => {
    switch (value) {
        case 0:
            return 'Физическое лицо'
        case 1:
            return 'Юридическое лицо'
        case 2:
            return 'Инсталлятор'
        default:
            return 'Пусто'
    }
}
