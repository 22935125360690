import Breadcrumb from 'react-bootstrap/Breadcrumb'
import {NavLink, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {StateType} from '../../store/store'
import {useEffect, useMemo} from 'react'
import {getHosts} from '../../store/hostsReducer'
import {getGroups} from '../../store/groupsReducer'

export const MyBreadcrumb = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const controller = useMemo(() => new AbortController(), [])
    const hosts = useSelector((state: StateType) => state.hostsReducer.hosts)
    const groups = useSelector((state: StateType) => state.groupsReducer.groups)

    useEffect(() => {
        if (!hosts) dispatch(getHosts({controller}))
        if (!groups) dispatch(getGroups({controller}))
    }, [dispatch, controller, groups, hosts])

    const breadcrumbs = useMemo(() => {
        if (location?.pathname && hosts && groups) {
            const arr: any = []
            const locat = location.pathname.split('/')

            switch (locat[1]) {
                case 'hosts':
                    if (locat[2]) {
                        const host = hosts.find(i => i.id === locat[2])
                        if (host) {
                            arr.push(createBreadcrumb('/hosts', 'Объекты'))
                            arr.push(createBreadcrumb(`/groups/${host.groupId}`, host.groupName))
                            arr.push(createBreadcrumb(`/hosts/${host.id}`, host.name, true))
                        }
                    }
                    break
                case 'devices':
                    if (locat[2]) {
                        let device
                        const host = hosts.find(h => {
                            const dev = h.items.find(d => d.id === locat[2])

                            if (dev) {
                                device = dev
                                return true
                            }
                            return false
                        })
                        if (device && host) {
                            arr.push(createBreadcrumb('/hosts', 'Объекты'))
                            arr.push(createBreadcrumb(`/groups/${host.groupId}`, host.groupName))
                            arr.push(createBreadcrumb(`/hosts/${host.id}`, host.name))
                            // @ts-ignore
                            arr.push(createBreadcrumb(`/devices/${device.id}`, device.name, true))
                        }
                    }
                    break
                case 'groups':
                    if (locat[2]) {
                        arr.push(createBreadcrumb('/groups', 'Группы'))
                        const group = groups.find(i => i.id === locat[2])
                        if (group) arr.push(createBreadcrumb(`/groups/${group.id}`, group.name, true))
                    }
                    break
                case 'problems':
                    if (locat[2]) {
                        arr.push(createBreadcrumb('/problems', 'Проблемы'))
                        const problem = hosts.find(i => i.id === locat[2])
                        if (problem) {
                            arr.push(createBreadcrumb(`/groups/${problem.groupId}`, problem.groupName))
                            arr.push(createBreadcrumb(`/problems/${problem.id}`, problem.name, true))
                        }
                    }
                    break
            }

            return arr
        }
    }, [location?.pathname, hosts, groups])

    return (
        <Breadcrumb>
            {breadcrumbs}
        </Breadcrumb>
    )
}

const createBreadcrumb = (link: string, name: string, active = false) => {
    return <Breadcrumb.Item active={active} linkProps={{to: link}} linkAs={NavLink} href={link} key={link}>{name}</Breadcrumb.Item>
}
