import {createSlice} from '@reduxjs/toolkit'
import {AjaxAPI, CreateAjaxAuthType} from '../api/API'
import {AjaxAuthType} from '../Types/Types'
import {asyncCreator} from './appReducer'

type InitialStateType = {
    ajaxAuthAll: Array<AjaxAuthType> | null
}

const initialState: InitialStateType = {
    ajaxAuthAll: null
}

export const ajaxAuthReducer = createSlice({
    name: 'ajaxAuthReducer',
    initialState,
    reducers: {
        setAjaxAuthAll(state, {payload}) {
            state.ajaxAuthAll = payload
        }
    }
})

export const {setAjaxAuthAll} = ajaxAuthReducer.actions

export default ajaxAuthReducer.reducer

export const getAjaxAuthAll = asyncCreator<{ profileId: string, controller: any }>('ajaxAuthReducer/getAjaxAuthAll', AjaxAPI.getAjaxAuthAll, setAjaxAuthAll)
export const createAjaxAuth = asyncCreator<CreateAjaxAuthType>('ajaxAuthReducer/createAjaxAuth', AjaxAPI.createAjaxAuth, null, 'Успешно добавлено')
export const editAjaxAuth = asyncCreator<CreateAjaxAuthType & { id: number }>('ajaxAuthReducer/editAjaxAuth', AjaxAPI.editAjaxAuth, null, 'Изменения сохранены')
export const deleteAjaxAuth = asyncCreator<{ id: number }>('ajaxAuthReducer/deleteAjaxAuth', AjaxAPI.deleteAjaxAuth, null, 'Успешно удалено')
