import {configureStore} from '@reduxjs/toolkit'
import appReducer from './appReducer'
import problemsReducer from './problemsReducer'
import groupsReducer from './groupsReducer'
import hostsReducer from './hostsReducer'
import deviceReducer from './deviceReducer'
import ajaxAuthReducer from "./AjaxAuthReducer";

export const store = configureStore({
    reducer: {
        appReducer,
        problemsReducer,
        groupsReducer,
        hostsReducer,
        deviceReducer,
        ajaxAuthReducer,
    }
})

export type StateType = ReturnType<typeof store.getState>
