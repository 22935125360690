import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {StateType} from '../../store/store'
import {priorityColorDefinitionBackground} from '../../helpers/priorityColorDefinition'
import {usePagination} from '../../hooks/usePagination'
import Select from 'react-select'
import {limitOptionsSelect} from '../../helpers/tableHelper'
import {NavLink, useHistory, useLocation, useParams} from 'react-router-dom'
import {filterToString} from '../../helpers/filterToString'
import {filterFromString} from '../../helpers/filterFromString'
import deepEqual from 'deep-equal'
import {Helmet} from 'react-helmet-async'
import {getHost, setHost, setHostDevicesFilter} from '../../store/hostsReducer'
import {priorityDecoder} from '../../helpers/priorityDecoder'
import {getDuration} from '../../helpers/getDuration'
import Moment from 'moment'
import {BackButton} from '../../components/BackButton/BackButton'
import {multipleExist} from '../../helpers/multipleExist'
import {deleteDevice} from '../../store/deviceReducer'
import {Button, Modal} from 'react-bootstrap'
import {Loading} from '../../components/Loading/Loading'
import {MyBreadcrumb} from '../../components/MyBreadcrumb/MyBreadcrumb'
import {errorHandler, setMessage, setStatus, setToken} from "../../store/appReducer";
import {AjaxItemType} from "../../Types/Types";
import {AjaxAPI} from "../../api/API";
import {getAjaxAuthAll} from "../../store/AjaxAuthReducer";
import {MyInput} from "../../components/MyForms/MyInput";
import {MySelect} from "../../components/MyForms/MySelect";
import {useFormik} from "formik";
import * as yup from "yup";
import {MyOption} from "../../components/MyForms/MyInputSearchPicker";
import ProgressBar from 'react-bootstrap/ProgressBar';

const schema = yup.object().shape({
    name: yup.string().required('Введите название'),
    idAjaxHub: yup.string().required('Введите ID Ajax Hub'),
    idAjaxAuth: yup.object().shape({
        label: yup.string().required(),
        value: yup.number().min(1, 'Выберите аккаунт').required('Выберите аккаунт'),
    })
})

export const Host = () => {
    const id = useParams<{ id: string }>().id
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const token = useSelector((state: StateType) => state.appReducer.token)
    const host = useSelector((state: StateType) => state.hostsReducer.host)
    const filter = useSelector((state: StateType) => state.hostsReducer.devicesFilter)
    const ajaxAuthAll = useSelector((state: StateType) => state.ajaxAuthReducer.ajaxAuthAll)
    const profileId = useSelector((state: StateType) => state.appReducer.profileId)
    const controller = useMemo(() => new AbortController(), [])
    const [checked, setChecked] = useState<Array<string>>([])
    const [checkedItemsOnline, setCheckedItemsOnline] = useState<Array<string>>([])
    const [checkedItemsBattery, setCheckedItemsBattery] = useState<Array<string>>([])
    const [checkedItemsTamper, setCheckedItemsTamper] = useState<Array<string>>([])
    const [loading, setLoading] = useState(false)
    const [ajaxItemsFiltered, setAjaxItemsFiltered] = useState<Array<AjaxItemType & {ajaxTriggerType?: Array<string>}>>([])
    const [selectedAjaxHub, setSelectedAjaxHub] = useState<MyOption | null>(null)
    const [showModal, setShowModal] = useState(false)
    const [showModalAjaxItems, setShowModalAjaxItems] = useState(false)
    const [showModalAddAjaxHub, setShowModalAddAjaxHub] = useState(false)
    const [progressTotal, setProgressTotal] = useState(0)
    const [progressCurrent, setProgressCurrent] = useState(0)
    const {CustomPagination} = usePagination(filter, host?.items?.length, (value) => {
        dispatch(setHostDevicesFilter(value))
        history.push({search: filterToString(value)})
        dispatch(getHost({id, controller}))
        setChecked([])
    })
    const timer = useRef<any>()

    const onHideAjaxItemModal = () => {
        setShowModalAjaxItems(false)
        setAjaxItemsFiltered([])
    }

    const formik = useFormik({
        initialValues: {
            name: 'Ajax Hub',
            idAjaxHub: '',
            idAjaxAuth: {value: 0, label: ''}
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async () => {
            if (host && values.idAjaxHub && values.idAjaxAuth?.value && token) {
                try {
                    setLoading(true)
                    const res = await AjaxAPI.createHub({
                        auth: token,
                        controller,
                        hostId: host.id,
                        // @ts-ignore
                        idAjaxAuth: values.idAjaxAuth.value,
                        idAjaxHub: values.idAjaxHub.toUpperCase(),
                        idAjaxDevice: values.idAjaxHub.toUpperCase(),
                        name: values.name,
                        status: '1',
                        priority: 4
                    })
                    if (res?.error) dispatch(setMessage({type: 'error', message: res.error.message}))
                    else {
                        dispatch(getHost({id, controller}))
                        dispatch(setMessage({type: 'success', message: `Хаб добавлен`}))
                        setShowModalAddAjaxHub(false)
                    }
                } catch (e: any) {
                    errorHandler(e, dispatch)
                } finally {
                    setLoading(false)
                }
            }
        }
    })
    const {values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, setFieldValue, isValid, resetForm} = formik

    useEffect(() => {
        if (host?.items) {
            const filterString = filterFromString(location.search)
            if (filterString && !deepEqual(filterString, filter)) {
                dispatch(setHostDevicesFilter({...filterString}))
            } else if (location.search !== filterToString(filter)) {
                history.replace({search: filterToString(filter)})
            }
        }
    }, [dispatch, filter, history, location, host?.items])

    const ajaxAuthOptions = useMemo(() => ajaxAuthAll && [...ajaxAuthAll].map(({id, login}) => ({label: login, value: id})), [ajaxAuthAll])
    const ajaxHubsOptions = useMemo(() => host && [...host.items?.filter(i => i.idAjaxAuth && i.idAjaxHub)].map(({id, name}) => ({label: name, value: id})), [host])

    useEffect(() => {
        if (id) {
            dispatch(getHost({id, controller}))
            timer.current = setInterval(() => dispatch(getHost({id, controller, noLoading: true})), 30000)
        }
    }, [dispatch, id, controller, profileId])

    useEffect(() => {
        if (profileId) {
            dispatch(getAjaxAuthAll({profileId, controller}))
        }
    }, [dispatch, controller, profileId])

    useEffect(() => {
        return () => {
            dispatch(setHost(null))
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, controller])

    useEffect(() => {
        if (ajaxAuthOptions && !values?.idAjaxAuth?.value) {
            setFieldValue('idAjaxAuth', ajaxAuthOptions[0])
        }
    }, [values, ajaxAuthOptions, setFieldValue])

    useEffect(() => {
        if (ajaxHubsOptions && !selectedAjaxHub) {
            setSelectedAjaxHub(ajaxHubsOptions[0])
        }
    }, [ajaxHubsOptions, selectedAjaxHub])

    useEffect(() => {
        setAjaxItemsFiltered([])
    }, [selectedAjaxHub])

    const allIds = useMemo(() => host?.items?.slice(filter.offset, filter.limit.value + filter.offset).map(i => i.id) || [], [host, filter])
    const allItemsOnlineIds = useMemo(() => ajaxItemsFiltered.filter(i => typeof i.online === 'boolean' && i.ajaxTriggerType?.includes('online')).map(i => i.id) || [], [ajaxItemsFiltered])
    const allItemsBatteryIds = useMemo(() => ajaxItemsFiltered.filter(i => typeof i.model?.batteryChargeLevelPercentage === 'number' && i.ajaxTriggerType?.includes('battery')).map(i => i.id) || [], [ajaxItemsFiltered])
    const allItemsTamperIds = useMemo(() => ajaxItemsFiltered.filter(i => typeof i.model?.tampered === 'boolean' && i.ajaxTriggerType?.includes('tamper')).map(i => i.id) || [], [ajaxItemsFiltered])

    const checkAll = useCallback(() => {
        if (host && host?.items) {
            let ids = [...checked]
            const limitHosts = host.items.slice(filter.offset, filter.limit.value + filter.offset).map(i => i.id)
            if (multipleExist(ids, limitHosts)) {
                limitHosts.forEach(i => ids = ids.filter(h => h !== i))
            } else {
                limitHosts.forEach(i => {
                    if (!ids.includes(i)) ids = [...ids, i]
                })
            }
            setChecked(ids)
        }
    }, [host, filter, checked])

    const checkAllItemsOnline = useCallback(() => {
        if (ajaxItemsFiltered.length > 0) {
            let ids = [...checkedItemsOnline]
            const ajaxItemsFilteredIds = ajaxItemsFiltered.filter(i => typeof i.online === 'boolean' && i.ajaxTriggerType?.includes('online')).map(i => i.id)
            if (multipleExist(ids, ajaxItemsFilteredIds)) {
                ajaxItemsFilteredIds.forEach(i => ids = ids.filter(h => h !== i))
            } else {
                ajaxItemsFilteredIds.forEach(i => {
                    if (!ids.includes(i)) ids = [...ids, i]
                })
            }
            setCheckedItemsOnline(ids)
        }
    }, [ajaxItemsFiltered, checkedItemsOnline])

    const checkAllItemsBattery = useCallback(() => {
        if (ajaxItemsFiltered.length > 0) {
            let ids = [...checkedItemsBattery]
            const ajaxItemsFilteredIds = ajaxItemsFiltered.filter(i => typeof i.model?.batteryChargeLevelPercentage === 'number' && i.ajaxTriggerType?.includes('battery')).map(i => i.id)
            if (multipleExist(ids, ajaxItemsFilteredIds)) {
                ajaxItemsFilteredIds.forEach(i => ids = ids.filter(h => h !== i))
            } else {
                ajaxItemsFilteredIds.forEach(i => {
                    if (!ids.includes(i)) ids = [...ids, i]
                })
            }
            setCheckedItemsBattery(ids)
        }
    }, [ajaxItemsFiltered, checkedItemsBattery])

    const checkAllItemsTamper = useCallback(() => {
        if (ajaxItemsFiltered.length > 0) {
            let ids = [...checkedItemsTamper]
            const ajaxItemsFilteredIds = ajaxItemsFiltered.filter(i => typeof i.model?.tampered === 'boolean' && i.ajaxTriggerType?.includes('tamper')).map(i => i.id)
            if (multipleExist(ids, ajaxItemsFilteredIds)) {
                ajaxItemsFilteredIds.forEach(i => ids = ids.filter(h => h !== i))
            } else {
                ajaxItemsFilteredIds.forEach(i => {
                    if (!ids.includes(i)) ids = [...ids, i]
                })
            }
            setCheckedItemsTamper(ids)
        }
    }, [ajaxItemsFiltered, checkedItemsTamper])

    const handleCheck = useCallback((value: string) => {
        if (checked.includes(value)) setChecked(checked.filter(i => i !== value))
        else setChecked([...checked, value])
    }, [checked])

    const handleCheckItemOnline = useCallback((value: string) => {
        if (checkedItemsOnline.includes(value)) setCheckedItemsOnline(checkedItemsOnline.filter(i => i !== value))
        else setCheckedItemsOnline([...checkedItemsOnline, value])
    }, [checkedItemsOnline])

    const handleCheckItemBattery = useCallback((value: string) => {
        if (checkedItemsBattery.includes(value)) setCheckedItemsBattery(checkedItemsBattery.filter(i => i !== value))
        else setCheckedItemsBattery([...checkedItemsBattery, value])
    }, [checkedItemsBattery])

    const handleCheckItemTamper = useCallback((value: string) => {
        if (checkedItemsTamper.includes(value)) setCheckedItemsTamper(checkedItemsTamper.filter(i => i !== value))
        else setCheckedItemsTamper([...checkedItemsTamper, value])
    }, [checkedItemsTamper])

    const deleteHandler = async () => {
        try {
            setLoading(true)
            const {payload}: any = await dispatch(deleteDevice({id: checked.join(';'), controller}))
            if (payload) {
                dispatch(getHost({id, controller}))
                setShowModal(false)
                setChecked([])
            }
        } finally {
            setLoading(false)
        }
    }

    const getAjaxItems = useCallback(async () => {
        setCheckedItemsOnline([])
        setCheckedItemsBattery([])
        setCheckedItemsTamper([])
        if (selectedAjaxHub && token && host) {
            const hub = host?.items?.find(i => i.id === selectedAjaxHub.value)
            if (hub && hub.idAjaxHub && hub.idAjaxAuth) {
                try {
                    setLoading(true)
                    const res: any = await AjaxAPI.getItems({
                        auth: token,
                        idAjaxHub: hub.idAjaxHub,
                        idAjaxAuth: hub.idAjaxAuth,
                        controller
                    })
                    const notExistItems: Array<AjaxItemType & {ajaxTriggerType?: Array<string>}> = []
                    if (res.error) {
                        dispatch(setStatus('reject'))
                        if (res.error.code === -10) {
                            localStorage.removeItem('token')
                            dispatch(setToken(null))
                        }
                        dispatch(setMessage({type: 'error', message: res.error.message}))
                        return
                    } else {
                        res?.result?.forEach((a: AjaxItemType) => {
                            if (typeof a.online === 'boolean' && !host?.items?.find(i => i.idAjaxDevice === a.id && i.ajaxTriggerType === 'online')) {
                                if (!notExistItems.find(i => i.id === a.id && i.ajaxTriggerType?.includes('online'))) {
                                    const item = notExistItems.find(i => i.id === a.id)
                                    if (item && item.ajaxTriggerType) item.ajaxTriggerType?.push('online')
                                    else notExistItems.push({...a, ajaxTriggerType: ['online']})
                                }
                            }

                            if (typeof a.model?.batteryChargeLevelPercentage === 'number' && !host?.items?.find(i => i.idAjaxDevice === a.id && i.ajaxTriggerType === 'battery')) {
                                if (!notExistItems.find(i => i.id === a.id && i.ajaxTriggerType?.includes('battery'))) {
                                    const item = notExistItems.find(i => i.id === a.id)
                                    if (item && item.ajaxTriggerType) item.ajaxTriggerType?.push('battery')
                                    else notExistItems.push({...a, ajaxTriggerType: ['battery']})
                                }
                            }

                            if (typeof a.model?.tampered === 'boolean' && !host?.items?.find(i => i.idAjaxDevice === a.id && i.ajaxTriggerType === 'tamper')) {
                                if (!notExistItems.find(i => i.id === a.id && i.ajaxTriggerType?.includes('tamper'))) {
                                    const item = notExistItems.find(i => i.id === a.id)
                                    if (item && item.ajaxTriggerType) item.ajaxTriggerType?.push('tamper')
                                    else notExistItems.push({...a, ajaxTriggerType: ['tamper']})
                                }
                            }
                           })
                        if (notExistItems.length > 0) {
                            setAjaxItemsFiltered(notExistItems)
                            setShowModalAjaxItems(true)
                        } else dispatch(setMessage({type: 'info', message: 'Все оборудование добавлено'}))
                    }
                } catch (e: any) {
                    errorHandler(e, dispatch)
                } finally {
                    setLoading(false)
                }
            }
        }
    }, [selectedAjaxHub, token, controller, dispatch, host])

    const submitAjaxItems = useCallback(async () => {
        if (selectedAjaxHub && token && host) {
            const hub = host?.items?.find(i => i.id === selectedAjaxHub?.value)
            if (hub && hub.idAjaxHub && hub.idAjaxAuth) {
                setLoading(true)
                for await (const i of ajaxItemsFiltered) {
                    setProgressTotal(checkedItemsOnline.length + checkedItemsBattery.length + checkedItemsTamper.length)
                    setShowModalAjaxItems(false)
                    if (checkedItemsOnline.includes(i.id)) {
                        try {
                            const res = await AjaxAPI.createItem({
                                auth: token,
                                controller,
                                hostId: host.id,
                                name: `${hub.name} - ${i.deviceName} (Контроль сети)`,
                                idAjaxHub: hub.idAjaxHub,
                                idAjaxDevice: i.id,
                                masterItemId: hub.id,
                                status: '1',
                                priority: 3,
                                ajaxTriggerType: 'online'
                            })
                            if (res?.error) dispatch(setMessage({type: 'error', message: res.error.message}))
                            else dispatch(setMessage({type: 'success', message: `Оборудование ${i.deviceName} (${i.id}) (Контроль сети) добавлено`}))
                        } catch (e: any) {
                            errorHandler(e, dispatch)
                        } finally {
                            setProgressCurrent(prev => prev + 1)
                        }
                    }
                    if (checkedItemsBattery.includes(i.id)) {
                        try {
                            const res = await AjaxAPI.createItem({
                                auth: token,
                                controller,
                                hostId: host.id,
                                name: `${hub.name} - ${i.deviceName} (Контроль заряда)`,
                                idAjaxHub: hub.idAjaxHub,
                                idAjaxDevice: i.id,
                                masterItemId: hub.id,
                                status: '1',
                                priority: 3,
                                ajaxTriggerType: 'battery'
                            })
                            if (res?.error) dispatch(setMessage({type: 'error', message: res.error.message}))
                            else dispatch(setMessage({type: 'success', message: `Оборудование ${i.deviceName} (${i.id}) (Контроль заряда) добавлено`}))
                        } catch (e: any) {
                            errorHandler(e, dispatch)
                        } finally {
                            setProgressCurrent(prev => prev + 1)
                        }
                    }
                    if (checkedItemsTamper.includes(i.id)) {
                        try {
                            const res = await AjaxAPI.createItem({
                                auth: token,
                                controller,
                                hostId: host.id,
                                name: `${hub.name} - ${i.deviceName} (Контроль корпуса)`,
                                idAjaxHub: hub.idAjaxHub,
                                idAjaxDevice: i.id,
                                masterItemId: hub.id,
                                status: '1',
                                priority: 3,
                                ajaxTriggerType: 'tamper'
                            })
                            if (res?.error) dispatch(setMessage({type: 'error', message: res.error.message}))
                            else dispatch(setMessage({type: 'success', message: `Оборудование ${i.deviceName} (${i.id}) (Контроль корпуса) добавлено`}))
                        } catch (e: any) {
                            errorHandler(e, dispatch)
                        } finally {
                            setProgressCurrent(prev => prev + 1)
                        }
                    }
                }
                dispatch(getHost({id, controller}))
                onHideAjaxItemModal()
                setLoading(false)
                setCheckedItemsOnline([])
                setCheckedItemsBattery([])
                setCheckedItemsTamper([])
                setProgressTotal(0)
                setProgressCurrent(0)
            }
        }
    }, [checkedItemsOnline, checkedItemsBattery, checkedItemsTamper, token, host, ajaxItemsFiltered, controller, dispatch, id, selectedAjaxHub])

    const itemsMap = useMemo(() => host?.items && [...host.items].slice(filter.offset, filter.limit.value + filter.offset).map(i => {
        return (
            <tr key={i.id} style={{backgroundColor: i.value ? priorityColorDefinitionBackground(i.priority) : '#fff'}}>
                <td className="align-middle"><input className="table-checkbox"
                                                    onChange={() => handleCheck(i.id)}
                                                    checked={checked.includes(i.id)}
                                                    value={i.id}
                                                    type="checkbox"/>
                </td>
                <td className="align-middle"><NavLink className="custom-link-table-item"
                                                      onClick={(e) => e.stopPropagation()}
                                                      to={`/devices/${i.id}`}>{i.name}</NavLink></td>
                <td className="align-middle">{priorityDecoder(i.priority)}</td>
                <td className="align-middle">{i.port}</td>
                <td className="align-middle">{i.status === '1' ? 'Активировано' : 'Деактивировано'}</td>
                <td className="align-middle">{i.value === 1 ? 'ПРОБЛЕМА' : 'ОК'}</td>
                <td className="align-middle">{getDuration(i.duration)}</td>
                <td className="align-middle">{i.lastchange ? Moment.utc(i.lastchange * 1000).format('DD.MM.YYYY HH:mm:ss') : ''}</td>
                {/*<td className="align-middle">-</td>*/}
            </tr>
        )
    }), [host?.items, filter, checked, handleCheck])

    const ajaxItemsMap = useMemo(() => ajaxItemsFiltered && [...ajaxItemsFiltered].map(i => {
        return (
            <tr key={i.id}>
                <td className="align-middle">
                    <label style={{width: '100%'}}>
                        <input className="table-checkbox"
                               onChange={() => handleCheckItemOnline(i.id)}
                               checked={checkedItemsOnline.includes(i.id)}
                               value={i.id}
                               type="checkbox"
                               disabled={typeof i.online !== 'boolean' || !i.ajaxTriggerType?.includes('online')}
                        />
                    </label>
                </td>
                <td className="align-middle">
                    <label style={{width: '100%'}}>
                        <input className="table-checkbox"
                               onChange={() => handleCheckItemBattery(i.id)}
                               checked={checkedItemsBattery.includes(i.id)}
                               value={i.id}
                               type="checkbox"
                               disabled={typeof i.model?.batteryChargeLevelPercentage !== 'number' || !i.ajaxTriggerType?.includes('battery')}
                        />
                    </label>
                </td>
                <td className="align-middle">
                    <label style={{width: '100%'}}>
                        <input className="table-checkbox"
                               onChange={() => handleCheckItemTamper(i.id)}
                               checked={checkedItemsTamper.includes(i.id)}
                               value={i.id}
                               type="checkbox"
                               disabled={typeof i.model?.tampered !== 'boolean' || !i.ajaxTriggerType?.includes('tamper')}
                        />
                    </label>
                </td>
                <td className="align-middle">{i.id}</td>
                <td className="align-middle">{i.deviceName}</td>
                <td className="align-middle">{i.deviceType}</td>
            </tr>
        )
    }), [ajaxItemsFiltered, handleCheckItemOnline, handleCheckItemBattery, handleCheckItemTamper, checkedItemsOnline, checkedItemsBattery, checkedItemsTamper])

    if (!host) return <Loading/>

    return (
        <div className="container">
            <MyBreadcrumb/>
            <div className="backButton" onClick={() => history.goBack()}><BackButton/></div>
            <Helmet>
                <title>{host?.name}</title>
            </Helmet>
            <h2 className="mt-1 mb-1">{host?.name}</h2>
            <div style={{fontSize: 16}}>ID: {host?.id}</div>
            <div style={{fontSize: 16}}>Протокол: {host?.https ? 'HTTPS' : 'HTTP'}</div>
            <div style={{fontSize: 16}}>IP-адрес: {host?.ip}</div>
            <div style={{fontSize: 16}}>Порт управления: {host?.controlPort}</div>
            <div style={{fontSize: 16}}>Физический адрес: {host?.address}</div>
            <div
                style={{fontSize: 16}}>Местоположение: {host?.lat && host?.lon ? 'Установлено' : 'Не установлено'}</div>
            {
                host?.ip && host?.controlPort
                    ? <div><a target="_blank" rel="noreferrer"
                              href={`${host?.https ? 'HTTPS' : 'HTTP'}://${host?.ip}:${host?.controlPort}`}>Управление</a>
                    </div>
                    : null
            }
            <NavLink to={{pathname: `/formHost/${host?.id}`}}
                     className="btn btn-primary mt-2"
            >
                Редактировать
            </NavLink>
            <hr className="mt-4 mb-3"/>
            <h2 className="mb-2">Оборудование</h2>
            <div style={{display: 'flex', alignItems: 'flex-start'}}>
                <NavLink to={{pathname: '/formDevice', state: {hostId: host?.id}}}
                         className="btn btn-primary"
                >
                    Добавить оборудование
                </NavLink>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 10}}>
                    <Button variant="primary"
                            onClick={() => {
                                resetForm()
                                setShowModalAddAjaxHub(true)
                            }}
                    >
                        Добавить Ajax Systems Hub
                    </Button>
                    {
                        ajaxHubsOptions && ajaxHubsOptions.length > 0 ? <Button variant="link"
                                                                                onClick={() => setShowModalAjaxItems(true)}
                                                                                style={{marginTop: 0, marginBottom: 0}}
                        >
                            Запрос оборудования Ajax Systems
                        </Button> : null
                    }
                </div>
            </div>
            <div className="table-responsive " style={{flex: 1}}>
                <div className="selectBlock">
                    <Select className="searchSelect"
                            value={filter.limit}
                            onChange={(item) => history.replace({
                                search: filterToString({
                                    ...filter,
                                    limit: item,
                                    offset: 0
                                })
                            })}
                            options={limitOptionsSelect}
                            isSearchable={false}
                    />
                    <CustomPagination/>
                </div>
                <table className="table table-sm table-hover">
                    <thead>
                    <tr>
                        <th className="align-middle" scope="col"><input className="table-checkbox"
                                                                        checked={checked.length > 0 && multipleExist(checked, allIds)}
                                                                        onChange={checkAll}
                                                                        type="checkbox"/>
                        </th>
                        <th className="align-middle" scope="col">Название</th>
                        <th className="align-middle" scope="col">Важность</th>
                        <th className="align-middle" scope="col">Порт</th>
                        <th className="align-middle" scope="col">Статус</th>
                        <th className="align-middle" scope="col">Состояние</th>
                        <th className="align-middle" scope="col">Длительность</th>
                        <th className="align-middle" scope="col">Последнее отключение</th>
                        {/*<th className="align-middle" scope="col">Подтверждено</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {itemsMap}
                    </tbody>
                </table>
            </div>
            <div style={{display: 'flex'}}>
                <input type="submit"
                       className="btn btn-danger mb-1"
                       style={{marginRight: 10}}
                       value="Удалить"
                       onClick={() => setShowModal(true)}
                       disabled={loading || checked.length === 0}
                />
                {/*<input type="submit" className="btn btn-primary mb-1" style={{marginRight: 10}} value="Подтвердить" disabled={checked.length === 0}/>*/}
                <CustomPagination/>
            </div>
            {
                showModal
                    ? <Modal show
                             onHide={() => setShowModal(false)}
                    >
                        <Modal.Body style={{textAlign: 'center'}}>
                            <div>Вы уверены что хотите удалить оборудование?</div>
                            {checked.find(i => {
                                let find = false
                                host?.items.forEach(a => {
                                    if (i === a.id && a.idAjaxAuth) find = true
                                })
                                return find
                            }) ? <div>При удалении Ajax Hub удалится все связанное оборудование</div> : null}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={() => setShowModal(false)}>
                                Нет
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={deleteHandler}
                                    disabled={loading}
                            >
                                Да
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            {
                showModalAddAjaxHub
                    ? <Modal show
                             onHide={() => setShowModalAddAjaxHub(false)}
                    >
                        <Modal.Body style={{textAlign: 'center'}}>
                            <div style={{width: 300, margin: '0 auto'}}>
                                <MyInput value={values.name}
                                         label="Название"
                                         name="name"
                                         onChange={handleChange}
                                         onBlur={handleBlur}
                                         clear={() => {
                                             setFieldValue('name', '')
                                         }}
                                         isInvalid={touched.name && errors.name}
                                />
                                <MyInput value={values.idAjaxHub.toUpperCase()}
                                         label="Ajax Hub ID"
                                         name="idAjaxHub"
                                         onChange={handleChange}
                                         onBlur={handleBlur}
                                         clear={() => {
                                             setFieldValue('idAjaxHub', '')
                                         }}
                                         isInvalid={touched.idAjaxHub && errors.idAjaxHub}
                                />
                                {
                                    ajaxAuthOptions && ajaxAuthOptions.length > 0 ? <MySelect value={values.idAjaxAuth}
                                                                                              label="Аккаунт Ajax Systems"
                                                                                              options={ajaxAuthOptions}
                                                                                              onChange={(item) => setFieldValue('idAjaxAuth', item)}
                                            // @ts-ignore
                                                                                              error={touched.idAjaxAuth && errors.idAjaxAuth}
                                        />
                                        : <div className='mb-4'>
                                            <NavLink to='/ajaxSystems'>Необходимо добавить аккаунт Ajax Systems</NavLink>
                                        </div>
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={() => setShowModalAddAjaxHub(false)}>
                                Отмена
                            </Button>
                            <Button variant="primary"
                                    type='submit'
                                    disabled={loading || isSubmitting || !isValid}
                                    style={{marginLeft: 15}}
                                    onClick={(e: any) => handleSubmit(e)}
                            >
                                {loading ? 'Загрузка...' : 'Добавить'}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            {
                showModalAjaxItems && ajaxHubsOptions && ajaxHubsOptions.length > 0
                    ? <Modal show
                             onHide={onHideAjaxItemModal}
                             size={ajaxItemsMap?.length > 0 ? 'lg' : 'sm'}
                    >
                        <Modal.Body style={{textAlign: 'center'}}>
                            <div style={{width: 250, margin: '0 auto'}}>
                                <MySelect value={selectedAjaxHub}
                                          label="Ajax Systems Hub"
                                          options={ajaxHubsOptions}
                                          onChange={(item) => setSelectedAjaxHub(item)}
                                />
                            </div>
                            <Button variant="outline-secondary"
                                    onClick={onHideAjaxItemModal}>
                                Отмена
                            </Button>
                            <Button variant="primary"
                                    type='submit'
                                    disabled={loading || isSubmitting || !selectedAjaxHub}
                                    style={{marginLeft: 15}}
                                    onClick={getAjaxItems}
                            >
                                {loading ? 'Загрузка...' : 'Запросить'}
                            </Button>
                            {
                                ajaxItemsMap?.length > 0
                                    ? <table className="table table-sm mt-3">
                                        <thead>
                                        <tr>
                                            <th className="align-middle" scope="col" style={{width: 90}}>
                                                <label>
                                                    <div style={{marginBottom: 5}}>Контроль сети</div>
                                                    <input className="table-checkbox"
                                                           onChange={checkAllItemsOnline}
                                                           checked={checkedItemsOnline.length > 0 && multipleExist(checkedItemsOnline, allItemsOnlineIds)}
                                                           type="checkbox"
                                                    />
                                                </label>
                                            </th>
                                            <th className="align-middle" scope="col" style={{width: 90}}>
                                                <label>
                                                    <div style={{marginBottom: 5}}>Контроль заряда</div>
                                                    <input className="table-checkbox"
                                                           onChange={checkAllItemsBattery}
                                                           checked={checkedItemsBattery.length > 0 && multipleExist(checkedItemsBattery, allItemsBatteryIds)}
                                                           type="checkbox"
                                                    />
                                                </label>
                                            </th>
                                            <th className="align-middle" scope="col" style={{width: 90}}>
                                                <label>
                                                    <div style={{marginBottom: 5}}>Контроль корпуса</div>
                                                    <input className="table-checkbox"
                                                           onChange={checkAllItemsTamper}
                                                           checked={checkedItemsTamper.length > 0 && multipleExist(checkedItemsTamper, allItemsTamperIds)}
                                                           type="checkbox"
                                                    />
                                                </label>
                                            </th>
                                            <th className="align-middle" scope="col">ID</th>
                                            <th className="align-middle" scope="col">Название</th>
                                            <th className="align-middle" scope="col">Тип</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {ajaxItemsMap}
                                        </tbody>
                                    </table>
                                    : null}
                        </Modal.Body>
                        {
                            ajaxItemsMap?.length > 0
                                ? <Modal.Footer>
                                    <Button variant="outline-secondary"
                                            onClick={onHideAjaxItemModal}>
                                        Отмена
                                    </Button>
                                    <Button variant="primary"
                                            type='submit'
                                            onClick={submitAjaxItems}
                                            disabled={loading || (checkedItemsOnline?.length === 0 && checkedItemsBattery?.length === 0 && checkedItemsTamper?.length === 0)}
                                    >
                                        {loading ? 'Загрузка...' : 'Добавить'}
                                    </Button>
                                </Modal.Footer>
                                : null
                        }
                    </Modal>
                    : null
            }
            {
                progressTotal > 0
                    ? <Modal show centered>
                        <Modal.Body>
                            <ProgressBar animated
                                         now={progressCurrent}
                                         min={0}
                                         max={progressTotal}
                                         label={<div>{progressCurrent} / {progressTotal}</div>}
                            />
                        </Modal.Body>
                    </Modal>
                    : null
            }
        </div>
    )
}
