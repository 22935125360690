import React from 'react'

export const Instruction = () => {
    return (
        <div className="container mb-5">
            <h1 className="mt-6 mb-4">Инструкция пользователя по работе с сайтом</h1>
            <h3 id="registration">Регистрация</h3>
            <p>
                Для регистрации необходимо перейти на вкладку «Регистрация» и заполнить все поля формы, затем нажать кнопку «Зарегистрироваться».
                <br/>
                Вводите электронную почту, к которой у вас есть доступ - на неё придёт письмо с инструкцией по подтверждению регистрации.
                <br/>
                Пароль может состоять из символов английского алфавита и цифр.
            </p>
            <h4>Подтверждение регистрации</h4>
            <p>
                После отправки формы на электронную почту, которая была указана при регистрации, придёт письмо с ссылкой для подтверждения адреса почты.
                <br/>
                Перейдя по ссылке в письме, вы завершите регистрацию и сможете войти в личный кабинет на сайте.
            </p>
            <h3>Главная страница</h3>
            <p>
                На главной странице отображаются карточки групп и объектов, а также возникшие проблемы.
                <br/>
                Для каждого объекта отображается информация о количестве оборудования, его статусе и состоянии.
                <br/>
                При возникновении проблемы на объекте, он закрашивается в цвет, в зависимости от важности оборудования (низкая - жёлтый, средняя - оранжевый, высокая - красный).
            </p>
            <p>
                Вы можете перейти на страницу объекта, нажав на его название или нажав по ссылке «Перейти».
            </p>
            <h3>Объекты</h3>
            <p>
                Объект - это группа взаимосвязанных устройств, имеющих одинаковый IP-адрес.
            </p>
            <h4>Добавление объекта</h4>
            <p>
                Для добавления нового объекта перейдите на вкладку «Объекты» и нажмите на кнопку «Добавить объект».
                <br/>
                Далее заполните все поля формы и нажмите на кнопку «Добавить объект».
            </p>
            <h4>Страница объекта</h4>
            <p>
                Для перехода на страницу объекта перейдите на вкладку «Объекты» и нажмите на название объекта в таблице.
                <br/>
                На странице объекта отображается информация о самом объекте, а также таблица со списком оборудования объета.
            </p>
            <h4>Редактирование объекта</h4>
            <p>
                Для редактирования объекта перейдите на страницу объекта и нажмите на кнопку «Редактировать».
                <br/>
                Вы можете изменить следующую информацию об объекте:
            </p>
            <ul>
                <li>Название</li>
                <li>IP-адрес</li>
                <li>Порт для управления (необходим для перехода на страницу управления объектом со страницы объекта. Подробнее в разделе: <a href="src/pages/Instruction/Instruction#object-control">Управление объектом из личного кабинета</a>)</li>
                <li>Физический адрес</li>
            </ul>
            <p></p>
            <p>
                Внесённые изменения сохранятся только после нажатия кнопки «Сохранить изменения».
            </p>
            <h4 id="object-control">Управление объектом из личного кабинета</h4>
            <p>
                Вы можете перейти на страницу управления объектом прямо из личного кабинета.
                Для этого при редактировании данных объекта укажите порт для управления.
                Полсе этого на странице объекта помимо информации о нём будет отображаться ссылка «Управление», перейдя по которой вы перейдёте на страницу управления объектом.
            </p>
            <h4>Удаление объекта</h4>
            <p>
                При удалениии объекта удаляются все его устройства.
                Существует два способа удалить объект.
            </p>
            <p>
            </p>
            <ol>
                <li>Выбрать несколько объектов на странице «Объекты» и нажать на кнопку «Удалить»</li>
                <li>Перейти на страницу объекта, нажать на кнопку «Редактировать», в появившейся форме нажать на кнопку «Удалить»</li>
            </ol>
            <p></p>
            <h3>Оборудование</h3>
            <h4>Добавление оборудования в объект</h4>
            <p>
                Для добавления оборудования в объект, перейдите на страницу объекта и нажмите кнопку «Добавить оборудование».
                <br/>
                Форма добавления оборудования содержит следующие поля:
            </p>
            <ul>
                <li>Название</li>
                <li>Порт</li>
                <li>Важность</li>
            </ul>
            При возникновениии проблемы, оборудование с низкой важностью будет подсвечиватся жёлтым цветом, со средней - оранжевым, с высокой - красным.
            <p></p>
            <h4>Страница оборудования</h4>
            <p>
                Для перехода на страницу оборудования перейдите на страницу объекта и нажмите на название оборудования в таблице «Оборудование».
                <br/>
                На странице оборудования отображается информация о самом оборудовании, а также статистика его отключений за выбранный период.
            </p>
            <h4>Редактирование оборудования</h4>
            <p>
                Для редактирования оборудования перейдите на страницу оборудования и нажмите на кнопку «Редактировать».
                <br/>
                Вы можете изменить следующую информацию об оборудовании:
            </p>
            <ul>
                <li>Название</li>
                <li>Порт</li>
                <li>Важность</li>
                <li>Порт для управления (необходим для перехода на страницу управления оборудованием с его страницы в личном кабинете, также, как и для объекта. Подробнее в разделе: <a href="src/pages/Instruction/Instruction#object-control">Управление
                    объектом из личного
                    кабинета</a>)
                </li>
            </ul>
            <p></p>
            <p>
                Внесённые изменения сохранятся только после нажатия кнопки «Сохранить изменения».
            </p>
            <h4>Удаление оборудования</h4>
            <p>
                Существует три способа удалить оборудование.
            </p>
            <p>
            </p>
            <ol>
                <li>Выбрать несколько устройств на странице объекта и нажать на кнопку «Удалить»</li>
                <li>Перейти на страницу оборудования, нажать на кнопку «Редактировать», в появившейся форме нажать на кнопку «Удалить»</li>
                <li>Удалить объект. При этом удалится всё входящее в него оборудование</li>
            </ol>
            <p></p>
        </div>
    )
}
