import React, {memo, useMemo, useState} from 'react'
import {Button, Form, Spinner} from 'react-bootstrap'
import {Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from 'react-redux'
import {StateType} from '../../store/store'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {errorHandler, setMessage} from '../../store/appReducer'
import {timezones} from '../../helpers/timezones'
import {authAPI} from '../../api/API'
import {MyInput} from '../../components/MyForms/MyInput'
import {MySelect} from '../../components/MyForms/MySelect'

const schema = yup.object().shape({
    email: yup.string().trim().email('Это не email').required('Введите email'),
    passwd: yup.string().required('Введите пароль').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
        'Пароль должен быть длиной от 6 символов и содержать цифры, заглавные и строчные символы английского алфавита'
    ),
    dpasswd: yup.string().oneOf([yup.ref('passwd'), null], 'Пароли должны совпадать'),
    timezone: yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required('Выберите часовую зону'),
    }),
    agree: yup.boolean().isTrue('Вы должны принять условия')
})

export const Registration = memo(() => {
    const dispatch = useDispatch()
    const status = useSelector((state: StateType) => state.appReducer.status)
    const controller = useMemo(() => new AbortController(), [])
    const [result, setResult] = useState<'OK' | 'ERROR' | 'EXIST_EMAIL' | 'ANOTHER' | ''>('')

    const timezoneOptions = timezones.map(({value, label}) => ({label, value}))

    const formik = useFormik({
        initialValues: {
            email: '',
            passwd: '',
            dpasswd: '',
            timezone: timezones[15],
            agree: false
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            try {
                const data = await authAPI.registration({
                    email: values.email,
                    passwd: values.passwd,
                    dpasswd: values.dpasswd,
                    timezone: values.timezone.value,
                    controller
                })

                if (data.error) {
                    if (data.error.code === -4) dispatch(setMessage({type: 'error', message: 'Ошибка валидации'}))
                    if (data.error.code === -121) setResult('EXIST_EMAIL')
                    if (data.error.code === -120) setResult('ERROR')
                    else dispatch(setMessage({type: 'error', message: data.error.message}))
                } else if (data.result) {
                    setResult('OK')
                }
            } catch ( e ) {
                setResult('ANOTHER')
                errorHandler(e, dispatch)
            }
        }
    })
    const {values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, setFieldValue} = formik

    if (result === 'OK') return (
        <div className="container">
            <div className="alert alert-warning" role="alert">
                <p className="mb-1">На адрес <span style={{fontWeight: 'bold'}}>{values.email}</span> отправлено письмо с подтверждением
                    регистрации. Для подтверждения регистрации перейдите по ссылке в письме.
                </p>
                <p className="mb-0">Если письмо долго не приходит, проверьте папку «Спам».</p>
                <Button className="btn btn-secondary mt-2" onClick={() => setResult('')}>Назад</Button>
            </div>
        </div>
    )

    if (result === 'ANOTHER') return (
        <div className="container">
            <div className="alert alert-warning" role="alert">
                <p className="mb-0">Что-то пошло не так...</p>
                <Button className="btn btn-secondary mt-2" onClick={() => setResult('')}>Назад</Button>
            </div>
        </div>
    )

    return (
        <div className="text-center" style={{flex: 1, display: 'flex'}}>
            <Helmet>
                <title>Регистрация</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
                  style={{maxWidth: 330}}
            >
                <h1 className="mt-6 mb-4">Регистрация</h1>
                <MyInput value={values.email}
                         label="Электронная почта"
                         name="email"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         clear={() => {
                             setFieldValue('email', '')
                         }}
                         isInvalid={touched.email && errors.email}
                />
                <MyInput value={values.passwd}
                         label="Пароль"
                         name="passwd"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         clear={() => {
                             setFieldValue('passwd', '')
                         }}
                         isInvalid={touched.passwd && errors.passwd}
                         isPassword
                />
                <MyInput value={values.dpasswd}
                         label="Повторите пароль"
                         name="dpasswd"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         clear={() => {
                             setFieldValue('dpasswd', '')
                         }}
                         isInvalid={touched.dpasswd && errors.dpasswd}
                         isPassword
                />
                <MySelect value={values.timezone}
                          label="Часовая зона"
                          options={timezoneOptions}
                          onChange={(item) => setFieldValue('timezone', item)}
                          error={touched.timezone && !!errors.timezone && errors.timezone.value}
                />
                <div className="checkbox mb-3 mt-3">
                    <label>
                        <Form.Check type="checkbox"
                                    id="agree"
                                    checked={values.agree}
                                    onChange={handleChange}
                                    label={`Регистрируясь, вы соглашаетесь с`}
                        />
                        <a href="/agreement" target="_blank">условиями сайта</a>
                    </label>
                    {touched.agree && !!errors.agree && <div className="validation-error">{errors.agree}</div>}
                </div>
                {
                    result === 'EXIST_EMAIL' && <div className="form-group mt-4 mb-2">
                        <div className="alert alert-warning" role="alert">
                            Данная почта уже зарегистрирована в системе.
                        </div>
                    </div>
                }
                {
                    result === 'ERROR' && <div className="form-group mt-4 mb-2">
                        <div className="alert alert-danger" role="alert">
                            Не удалось отправить письмо на данный адрес. Проверьте правильность почтового адреса и повторите попытку.
                        </div>
                    </div>
                }
                <Button variant="primary"
                        type="submit"
                        disabled={status === 'loading' || isSubmitting || !values.agree}
                        style={{marginTop: 10}}
                >
                    {isSubmitting ? <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> : 'Регистрация'}
                </Button>
            </Form>
        </div>
    )
})
