import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AjaxAPI, deviceAPI} from '../api/API'
import {DeviceType, EventType, SelectOption} from '../Types/Types'
import {limitOptionsSelect} from '../helpers/tableHelper'
import {asyncCreator} from './appReducer'

export type EventsFilter = {
    limit: SelectOption<number>
    offset: number
}

type InitialStateType = {
    device: DeviceType | null
    events: Array<EventType> | null
    filter: EventsFilter
}

const initialState: InitialStateType = {
    device: null,
    events: null,
    filter: {
        limit: limitOptionsSelect[0],
        offset: 0
    },
}

export const deviceReducer = createSlice({
    name: 'objectsReducer',
    initialState,
    reducers: {
        setDevice(state, {payload}) {
            state.device = payload
        },
        setEvents(state, {payload}) {
            state.events = payload
        },
        setDeviceFilter(state, {payload}: PayloadAction<any>) {
            state.filter = payload
        },
    }
})

export const {
    setDevice, setEvents, setDeviceFilter
} = deviceReducer.actions

export default deviceReducer.reducer

export const getDevice = asyncCreator<{ id: string, controller: any }>('deviceReducer/getDevice', deviceAPI.getDevice, setDevice)
export const createDevice = asyncCreator<CreateDevice>('deviceReducer/createDevice', deviceAPI.createDevice, null, 'Оборудование добавлено')
export const editDevice = asyncCreator<EditDevice>('deviceReducer/editDevice', deviceAPI.editDevice, null, 'Изменения сохранены')
export const editAjaxHub = asyncCreator<EditDevice>('deviceReducer/editAjaxHub', AjaxAPI.editHub, null, 'Изменения сохранены')
export const editAjaxDevice = asyncCreator<EditDevice>('deviceReducer/editAjaxDevice', AjaxAPI.editItem, null, 'Изменения сохранены')
export const deleteDevice = asyncCreator<{ id: string }>('deviceReducer/deleteDevice', deviceAPI.deleteDevice, null, 'Оборудование удалено')
export const getEvents = asyncCreator<{ id: string, controller: any }>('deviceReducer/getEvents', deviceAPI.getEvents, setEvents)

type CreateDevice = {
    hostId: string
    name: string
    port: number | string
    priority: number
    status: string
}

type EditDevice = {
    id: string
    hostId: string
    name: string
    priority: number
    status: string
}
