import React, {Fragment, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {StateType} from '../../store/store'
import {getGroups} from '../../store/groupsReducer'
import Accordion from 'react-bootstrap/Accordion'
import {useAccordionButton} from 'react-bootstrap/AccordionButton'
import Card from 'react-bootstrap/Card'
import {NavLink} from 'react-router-dom'
import {priorityColorDefinitionBackground} from '../../helpers/priorityColorDefinition'

const CustomToggle = ({children, eventKey}: any) => {
    const decoratedOnClick = useAccordionButton(eventKey)

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            {children}
            <button className="btn btn-primary-outline collapsed pt-0 pb-0" onClick={decoratedOnClick}>показать/скрыть</button>
        </div>
    )
}

export const WidgetProblem = () => {
    const dispatch = useDispatch()
    const groups = useSelector((state: StateType) => state.groupsReducer.groups)
    const controller = useMemo(() => new AbortController(), [])

    useEffect(() => {
        dispatch(getGroups({controller}))

        return () => {
            controller.abort()
        }
    }, [dispatch, controller])

    const groupsMap = groups?.map(i => {
        if (!i.hosts || i.hosts.length === 0) return <Fragment key={i.id}></Fragment>
        const hostsMap = i.hosts?.map(h => {
            let activeDevice = 0
            let deactiveDevice = 0
            let problemDevice = 0
            let okDevice = 0
            let priority = 0

            h.items?.forEach(d => {
                if (d.value === 1 && d.priority > priority) {
                    priority = d.priority
                }
                if (d.status === '1') activeDevice += 1
                else deactiveDevice += 1
                if (d.value === 1) problemDevice += 1
                else okDevice += 1
            })

            return (
                <div key={h.id} className="card p-0 m-2" style={{width: 280}}>
                    <div className="card-header text-center" style={{backgroundColor: priorityColorDefinitionBackground(priority)}}>
                        <h5 className="m-2"><NavLink className="text-dark custom-link-table-item" to={`/hosts/${h.id}`}>{h.name}</NavLink></h5>
                    </div>
                    <div className="card-body">
                        <p>Количество оборудования: <span>{h.items?.length || 0}</span></p>
                        <p>Активировано/Деактивировано: <span className="text-success">{activeDevice}</span> / <span className="text-danger">{deactiveDevice}</span></p>
                        <p className="mb-0">Состояние (проблема/ок): <span className="text-danger">{problemDevice}</span> / <span className="text-success">{okDevice}</span></p>
                    </div>
                    <div className="card-footer text-center"><NavLink className="custom-link-table-item" to={`/hosts/${h.id}`}>Перейти</NavLink></div>
                </div>
            )
        })

        return (
            <Card key={i.id} style={{marginBottom: 15, marginTop: 15}}>
                <Card.Header>
                    <CustomToggle eventKey={i.id}>
                        <h5 className="m-2">
                            <NavLink className="text-dark custom-link-table-item" to={`/groups/${i.id}`}>{i.name}</NavLink>
                        </h5>
                    </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={i.id}>
                    <Card.Body style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                        {hostsMap}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    })

    return (
        <Accordion>
            {groupsMap}
        </Accordion>
    )
}
