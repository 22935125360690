export const priorityDecoder = (priority: number): string => {
    switch (priority) {
        case 1:
            return 'Низкая'
        case 3:
            return 'Средняя'
        case 4:
            return 'Высокая'
        default:
            return ''
    }
}
