import {createAsyncThunk, createSlice, Dispatch} from '@reduxjs/toolkit'
import {authAPI, EditProfileType, statisticsAPI} from '../api/API'
import {LoginFormType, ProfileType, StatisticsType, UserType} from '../Types/Types'
import {StateType} from './store'

type InitialStateType = {
    token: string | null
    login: string
    profile: UserType | null
    profileType: ProfileType
    profileId: string | null
    goToMain: boolean
    status: 'loading' | 'success' | 'reject' | 'idle'
    message: {
        message: string,
        type: 'success' | 'error' | 'info' | ''
    },
    statistics: StatisticsType | null
    selectedCity: { value: number, label: string } | null
    mapState: { center: Array<number>, zoom: number } | undefined
    mapOnlyProblem: boolean
}

const initialState: InitialStateType = {
    token: null,
    login: '',
    profile: null,
    profileType: null,
    profileId: null,
    goToMain: false,
    status: 'idle',
    message: {
        message: '',
        type: ''
    },
    statistics: null,
    selectedCity: null,
    mapState: undefined,
    mapOnlyProblem: false
}

export const appReducer = createSlice({
    name: 'appReducer',
    initialState,
    reducers: {
        setStatus(state, {payload}) {
            state.status = payload
        },
        setMessage(state, {payload}) {
            state.message = payload
        },
        setToken(state, {payload}) {
            state.token = payload
        },
        setLogin(state, {payload}) {
            state.login = payload
        },
        setProfileType(state, {payload}) {
            state.profileType = payload
        },
        setProfileId(state, {payload}) {
            state.profileId = payload
        },
        clearMessage(state) {
            state.message = {type: '', message: ''}
        },
        setStatistics(state, {payload}) {
            state.statistics = payload
        },
        setProfile(state, {payload}) {
            state.profile = payload
        },
        setSelectedCity(state, {payload}) {
            state.selectedCity = payload
        },
        setMapState(state, {payload}) {
            state.mapState = payload
        },
        setMapOnlyProblem(state, {payload}) {
            state.mapOnlyProblem = payload
        },
        setGoToMain(state, {payload}) {
            state.goToMain = payload
        },
    }
})

export const {
    setToken, setStatus, setMessage, setLogin, setProfileType, clearMessage, setStatistics, setProfile, setSelectedCity, setMapState, setMapOnlyProblem, setProfileId, setGoToMain
} = appReducer.actions

export default appReducer.reducer

export const errorHandler = (e: any, dispatch: Dispatch) => {
    console.log(JSON.stringify(e, null, 2))
    console.log(JSON.stringify(e.response, null, 2))
    dispatch(setStatus('error'))
    if (e.message === 'canceled') return
    if (e.response?.status === 500) dispatch(setMessage({type: 'error', message: 'Ошибка 500. Внутренняя ошибка сервера.'}))
    else dispatch(setMessage({type: 'error', message: e.message || e.response?.message || 'Что-то пошло не так...'}))
}

export const asyncCreator = <T>(prefix: string, apiMethod: any, reducerMethod?: any, message?: string, isDeleteMethod: boolean = false) => {
    return createAsyncThunk(
        prefix,
        async (params: T & { noLoading?: boolean, controller?: AbortController }, {dispatch, getState}) => {
            if (!params?.noLoading) dispatch(setStatus('loading'))
            try {
                const token = (getState() as StateType).appReducer.token
                const data = await apiMethod({...params, auth: token})
                if (data.error) {
                    dispatch(setStatus('reject'))
                    if (data.error.code === -10) {
                        localStorage.removeItem('token')
                        dispatch(setLogin(null))
                        dispatch(setProfileType(null))
                        dispatch(setProfileId(null))
                        dispatch(setToken(null))
                        dispatch(setGoToMain(true))
                    }
                    if (data.error.code === -400) dispatch(setMessage({type: 'info', message: data.error.message}))
                    else dispatch(setMessage({type: 'error', message: data.error.message}))
                    return false
                } else if (data.result) {
                    if (reducerMethod) {
                        if (isDeleteMethod) dispatch(reducerMethod(params))
                        else dispatch(reducerMethod(data.result))
                    }
                    if (message) dispatch(setMessage({type: 'success', message}))
                    dispatch(setStatus('success'))
                    return true
                }
                return data
            } catch ( e: any ) {
                errorHandler(e, dispatch)
            }
        }
    )
}

export const checkAuth = createAsyncThunk(
    'appReducer/checkAuth',
    async (_, {dispatch}) => {
        try {
            dispatch(setStatus('loading'))
            const token = localStorage.getItem('token')
            if (token !== null) {
                const data = await authAPI.authCheck(token)
                if (data.error) {
                    dispatch(setStatus('reject'))
                    localStorage.removeItem('token')
                    dispatch(setLogin(null))
                    dispatch(setProfileType(null))
                    dispatch(setProfileId(null))
                    dispatch(setToken(null))
                    dispatch(setGoToMain(true))
                    dispatch(setMessage({type: 'error', message: data.error.message}))
                } else if (data.result) {
                    dispatch(setLogin(data.result.login))
                    dispatch(setProfileType(data.result.type))
                    dispatch(setProfileId(data.result.id))
                    dispatch(setToken(token))
                    dispatch(setStatus('success'))
                }
            } else dispatch(setStatus('success'))
        } catch ( e ) {
            errorHandler(e, dispatch)
        }
    }
)

export const login = createAsyncThunk(
    'appReducer/login',
    async (form: LoginFormType, {dispatch}) => {
        try {
            dispatch(setStatus('loading'))
            const data = await authAPI.login(form)
            if (data.error) {
                dispatch(setStatus('reject'))
                if (data.error.code === -4) dispatch(setMessage({type: 'error', message: 'Ошибка валидации'}))
                if (data.error.code === -11) dispatch(setMessage({type: 'error', message: 'Введен неверный логин или пароль'}))
                else dispatch(setMessage({type: 'error', message: data.error.message}))
            } else if (data.result) {
                dispatch(setToken(data.result))
                if (form.isRemaining) localStorage.setItem('token', data.result)
                await dispatch(checkAuth())
                dispatch(setStatus('success'))
                return data.result
            }
        } catch ( e ) {
            errorHandler(e, dispatch)
        }
    }
)

export const logout = createAsyncThunk(
    'appReducer/logout',
    async (_, {dispatch}) => {
        try {
            dispatch(setStatus('loading'))
            localStorage.removeItem('token')
            dispatch(setLogin(null))
            dispatch(setProfileType(null))
            dispatch(setProfileId(null))
            dispatch(setToken(null))
            dispatch(setGoToMain(true))
            dispatch(setStatus('success'))
            return true
        } catch ( e ) {
            errorHandler(e, dispatch)
        }
    }
)

export const getStatistics = asyncCreator<undefined>('appReducer/getStatistics', statisticsAPI.getStatistics, setStatistics)
export const getProfile = asyncCreator('appReducer/getProfile', authAPI.getProfile, setProfile)
export const editProfile = asyncCreator<EditProfileType>('appReducer/editProfile', authAPI.editProfile)
